import React, { Component } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Label, Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import config from "../../config.json";



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            modal: false,
            categories: [],
            errorMsg: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }


    componentDidMount() {
        const { match, location, history } = this.props;
        // console.log(location)
        // if (location.state && location.state.id) {
        //     this.getDriverDetails(location.state.id)
        //     this.setState({
        //         id: location.state.id
        //     })
        // }
    }

    handleValidSubmit = (event, values) => {
        this.setState({ values });

        console.log("values", values);

        if (values) {
            try {
                let driverKey = sessionStorage.getItem("secretKey");
                let driverId = sessionStorage.getItem("userId");
                // values.email=this.state.email;
                if (values.newPassword===values.confirmPassword) {
                    this.updateDriver(values, driverId, driverKey);   
                } else {
                    this.setState({ errorMsg: "New Password and Confirm Password should match" });
                }
            } catch (error) {
                console.log(error);
            }
        }

    }

    updateDriver = async (Object, driverId, driverKey) => {
        fetch(config.DELIVERY_API + "changePassword/" + driverId + "/" + driverKey , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(Object),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    this.setState({ errorMsg: data.message || data.error });
                }
                else {
                    this.setState({ errorMsg: "" });
                    this.toggle();
                    // this.form && this.form.reset();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ errorMsg: error });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Drivers" breadcrumbItem="Change Password" />

                        {this.state.errorMsg ? <Alert color="danger">{this.state.errorMsg}</Alert> : ''}

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                    <div className="p-2">
                                    <AvForm
                                        className="form-horizontal"
                                        onValidSubmit={this.handleValidSubmit}
                                    >
                                        {this.props.user && this.props.user ? (
                                        <Alert color="success">
                                            Register User Successfully
                                        </Alert>
                                        ) : null}
                                        {this.props.registrationError &&
                                        this.props.registrationError ? (
                                        <Alert color="danger">
                                            {this.props.registrationError}
                                        </Alert>
                                        ) : null}

                                        <div className="form-group">
                                        <AvField
                                            name="phoneNumber"
                                            label="Phone Number"
                                            className="form-control"
                                            placeholder="Enter Phone Number"
                                            type="tel"
                                            required
                                        />
                                        </div>

                                        <div className="form-group">
                                        <AvField
                                            name="oldPassword"
                                            label="Current Password"
                                            type="password"
                                            required
                                            placeholder="Enter Current Password"
                                        />
                                        </div>

                                        <div className="form-group">
                                        <AvField
                                            name="newPassword"
                                            label="New Password"
                                            type="password"
                                            required
                                            placeholder="Enter New Password"
                                        />
                                        </div>
                                        <div className="form-group">
                                        <AvField
                                            name="confirmPassword"
                                            label=" Confirm Password"
                                            type="password"
                                            required
                                            placeholder="Confirm Password"
                                        />
                                        </div>

                                        <div className="mt-4">
                                        <button
                                            className="btn btn-primary btn-block waves-effect waves-light"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                        </div>

                                        {/* <div className="mt-4 text-center">
                                        <p className="mb-0">
                                            By registering you agree to the Dev Trac policies{" "}
                                            <Link to="#" className="text-primary">
                                            Terms of Use
                                            </Link>
                                        </p>
                                        </div> */}
                                    </AvForm>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} >
                                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                                <ModalBody>
                                    Changed Password Successfully
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" tag={Link} to={{
                                        pathname: '/dashboard',
                                    }} onClick={this.toggle}>OK</Button>{' '}
                                    {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                                </ModalFooter>
                            </Modal>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ChangePassword;