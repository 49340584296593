import React, { Component } from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Task Cards
import UncontrolledBoard from "./UncontrolledBoard";

//Import Images
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

import "./tasks.scss";

class TasksKanban extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				
				  {
					id: 1,
					title: '1',
					cards: [
					  {
						id: 1,
						content: {
						  title: "Topnav layout design", date: "14 Oct, 2019", img: avatar4, text: "3 +", budget: 145, status: "Waiting", class: "secondary"
						}
					  },
					  {
						id: 2,
						content: {
						  title: "Create a New Landing UI", date: "15 Oct, 2019", img: avatar5, text: "3 +", budget: 112, status: "Approved", class: "primary"
						}
					  },
					  {
						id: 3,
						content: {
						  title: "Create a Skote Logo", date: "16 Oct, 2019", img: avatar4, text: "F", budget: 86, status: "Waiting", class: "secondary"
						}
					  },
					]
				  },
				  {
					id: 2,
					title: '2',
					cards: [
					  {
						id: 4,
						content: {
						  title: "Topnav layout design", date: "14 Oct, 2019", img: avatar4, text: "3 +", budget: 145, status: "Waiting", class: "secondary"
						}
					  },
					  {
						id: 5,
						content: {
						  title: "Create a New Landing UI", date: "15 Oct, 2019", img: avatar5, text: "3 +", budget: 112, status: "Approved", class: "primary"
						}
					  },
					  {
						id: 6,
						content: {
						  title: "Create a Skote Logo", date: "16 Oct, 2019", img: avatar4, text: "F", budget: 86, status: "Waiting", class: "secondary"
						}
					  },
					]
				  },
				  {
					id: 3,
					title: '3',
					cards: [
					  {
						id: 7,
						content: {
						  title: "Topnav layout design", date: "14 Oct, 2019", img: avatar4, text: "3 +", budget: 145, status: "Waiting", class: "secondary"
						}
					  },
					  {
						id: 8,
						content: {
						  title: "Create a New Landing UI", date: "15 Oct, 2019", img: avatar5, text: "3 +", budget: 112, status: "Approved", class: "primary"
						}
					  },
					  {
						id: 9,
						content: {
						  title: "Create a Skote Logo", date: "16 Oct, 2019", img: avatar4, text: "F", budget: 86, status: "Waiting", class: "secondary"
						}
					  },
					]
				  },
			]
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content" style={{margin:0, paddingLeft:0, paddingBottom:0, paddingTop: 45, paddingRight:0}}>
					<Container fluid>
						{/* Render Breadcrumbs */}
						<Breadcrumbs title="Order" breadcrumbItem="Assign Order" />
						<UncontrolledBoard board={this.state} content={this.state.columns} />
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default TasksKanban;