import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, UncontrolledTooltip } from "reactstrap";

import Axios from 'axios';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import config from "../../config.json";
import axios from "axios";

class ContactsGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drivers: [],
            modal: false,
            selectedDriverId: '',
            selectedDriverName: ''
        }
        this.togglemodal.bind(this);
    }


    getDrivers = async (driverId, driverKey) => {
        this.setState({
            loading: true,
        })
        try {
            const response = await axios.get(config.DELIVERY_API + "findAllDrivers/" + driverId + "/" + driverKey, {
                pageNo: 0,
                pageSize: 100
            })
            if (response.status === 200) {
                var data = response.data;
                var rows = data.map(driver => ({
                    driverName: driver.firstName + " " + driver.lastName,
                    notify:<>
                        {!driver.notify &&
                            <Link data-toggle="tooltip" to="#" data-placement="bottom" title="Make available" onClick={() => this.availableUser(driver.id)} className="text-body font-size-16">
                                <i style={{ color: 'red' }} className="bx bx-bell-off"></i>
                            </Link>}
                        {driver.notify &&
                            <Link data-toggle="tooltip" to="#" data-placement="bottom" title="Make unavailable" onClick={() => this.unavailableUser(driver.id)} className="text-body font-size-16">
                                <i style={{ color: 'green' }} className="bx bx-bell"></i>
                            </Link>
                        }
                    </>,
                    phoneNumber: driver.phoneNumber,
                    info: driver.formatLastCompletedOrder,
                    actions: <>
                        <Link data-toggle="tooltip" data-placement="bottom" title="Edit driver info" to={{
                                        pathname: '/edit-driver',
                                        state: driver
                                    }} id={"edit" + driver.id} className="text-body font-size-18">
                            <i style={{ color: 'green' }} className="bx bx-edit"></i>
                        </Link>
                        <span>{"   "}</span>
                        <Link data-toggle="tooltip" to="#" data-placement="bottom" title="Delete driver" onClick={() => this.deleteUser(driver.id, driver.firstName)} className="text-body font-size-18">
                            <i style={{ color: 'red' }} className="bx bx-trash"></i>
                        </Link>
                    </>,
                }));

                var columns = [
                    {
                      label: "Name",
                      field: "driverName",
                      sort: "asc"
                    },
                    {
                        label: "Notification",
                        field: "notify"
                    },
                    {
                        label: "Mobile number",
                        field: "phoneNumber"
                    },
                    {
                      label: "Last activity",
                      field: "info"
                    },
                    {
                        label: "Actions",
                        field: "actions"
                      },
                ];

                var driverDatas = {rows, columns}
                
                this.setState({
                    drivers: driverDatas,
                    modal: false,
                    loading: false
                })
            }

        } catch (error) {
            this.setState({
                loading: false,
            });
        }
    }

    confirmDelete = (id, name) => {
        this.setState({
            selectedDriverId: id,
            selectedDriverName: name,
            modal: true
        })
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    deleteDriver = () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(
            config.DELIVERY_API +
            "deleteDriver/" +
            driverId +
            "/" +
            driverKey +
            "/" +
            this.state.selectedDriverId
        )
            .then((response) => response.json())
            .then((data) => {
                this.getDrivers(driverId, driverKey);
            });
    }

    deleteUser(id, name) {
        console.log(id, name)
        this.confirmDelete(id, name);
    }

    async availableUser(id) {
        console.log(id)
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        Axios.get(config.DELIVERY_API + "updateDriverAvailability/" + driverId + "/" + driverKey + "/" + id + "/true").then((response) => {
            if (response && response.status === 200) {
                this.getDrivers(driverId, driverKey);
            } else {
                console.error('error' + JSON.stringify('Failed to connect'));
            }
        })
    }

    async unavailableUser(id) {
        console.log(id);
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        Axios.get(config.DELIVERY_API + "updateDriverAvailability/" + driverId + "/" + driverKey + "/" + id + "/false").then((response) => {
            if (response && response.status === 200) {
                this.getDrivers(driverId, driverKey);
            } else {
                console.error('error' + JSON.stringify('Failed to connect'));
            }
        })
    }

    async componentDidMount() {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        this.setState({
            driverKey,
            driverId
        })
        // await this.getBasicInfo('notification', driverId, driverKey);
        this.getDrivers(driverId, driverKey)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="Drivers" />

                        <Row>
                            
                            <Col xs="12">
                                <div>
                                    <span className="text-body font-size-14">Total drivers : {this.state.drivers.rows ? this.state.drivers.rows.length : 0}</span>
                                    <MDBDataTable entries={50} exportToCSV small noBottomColumns responsive striped bordered hover data={this.state.drivers} />
                                </div>
                            </Col>
                            
                        </Row>

                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.togglemodal} className="modal-dialog modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>Alert! Do you want to delete the Driver?</ModalHeader>
                                <ModalBody>Driver will be removed permanetly and App access will be revoked after this deletion. Selected driver is {<span style={{ color: 'red' }}>{this.state.selectedDriverName}</span>}</ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="danger"
                                        tag={Link}
                                        onClick={this.deleteDriver}
                                    >
                                        OK
                                    </Button>
                                    <Button color="secondary" onClick={this.togglemodal}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactsGrid;