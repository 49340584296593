
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

class DriverMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            title: "Drivers"
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle style={{color:"#fff"}} className="btn waves-effect" id="page-header-user-dropdown" tag="button">
                        <i className="bx bx-car font-size-16 align-middle mr-1"></i>
                        <span className="d-none d-xl-inline-block ml-2 mr-1">
                            {this.state.title}
                        </span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="/contacts-grid">
                            <i className="bx bxs-car font-size-16 align-middle mr-1"></i>
                            {this.props.t('Drivers')}
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <DropdownItem tag="a" href="/projects-create">
                            <i className="bx bx-list-plus font-size-16 align-middle mr-1"></i>
                            {this.props.t('Add driver')}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(DriverMenu));
