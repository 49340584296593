import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import axios from "axios";
import config from "../../config.json";
import { debounce } from "../../helpers/utils";
import { MDBDataTable } from "mdbreact";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Cookies from 'js-cookie';


class OrderCreate extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      selectedFiles: [],
      categories: [],
      newProductName: "",
      s3FileName: "",
      calcDeliveryModal: false,
      placeDeliveryModal: false,
      fields: {},
      storeId: "",
      delivery_value: "",
      delivery_description: "",
      driverKey: "",
      driverId: "",
      userRole: "",
      toAddressLine1Auto: [],
      selectedGroup: null,
      optionGroup: [],
      selectedPay: null,
      optionPay: [],
      selectedAddressType: null,
      optionAddressType: [],
      stores: [],
      stay: false,
      mobile_info: [],
      address_info: [],
      currentDateTime: '',
      preparationTimeInTime: '',
      columns: [
        {
          label: "Phone number",
          field: `deliveryMobileNumber`,
          sort: "asc",
          width: 150,
        },
        {
          label: "First Name",
          field: "deliveryFirstName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Name",
          field: "deliveryLastName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Address",
          field: `addressLine1`,
          sort: "asc",
          width: 150,
        },
        {
          label: "Address#",
          field: `addressType`,
          sort: "asc",
          width: 150,
        },
        {
          label: "#",
          field: `use`,
          width: 150,
        },
      ],
      columns_address: [
        {
          label: "Address",
          field: `addressLine1`,
          sort: "asc",
          width: 150,
        },
        {
          label: "City#",
          field: `city`,
          sort: "asc",
          width: 150,
        },
        {
          label: "#",
          field: `use`,
          width: 150,
        },
      ],
    };
  }

  componentDidMount() {
    let addressType = Cookies.get('addressType');
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");
    var userRoleInSession = sessionStorage.getItem("userRole");
    var today = new Date(),
    time = ('0'+today.getHours()).slice(-2) + ':' + ('0'+today.getMinutes()).slice(-2);
    this.getAllStores(driverId, driverKey);
    this.setState({
      driverKey,
      driverId,
      userRole: userRoleInSession,
      currentDateTime: time,
      selectedPay: { value: 'PAID', label: 'Pre-paid' }
    });
    const { match, location, history } = this.props;
    if (addressType == 'HOME') {
      this.setState({selectedAddressType: { value: 'HOME', label: 'Home' }})
    } else if (addressType == 'WORK') {
      this.setState({selectedAddressType: { value: 'WORK', label: 'Work' }})
    } else if (addressType == 'FRIEND') {
      this.setState({selectedAddressType: { value: 'FRIEND', label: 'Friend' }})
    } else if (addressType == 'Other') {
      this.setState({selectedAddressType: { value: 'Other', label: 'Other' }})
    } else {
      this.setState({selectedAddressType: { value: 'HOME', label: 'Home' }})
    }
  }

  getAllStores = async (driverId, driverKey) => {
    this.setState({
      loading: true,
    });
    try {
      console.log(config.DELIVERY_API);
      const response = await axios.get(
        config.DELIVERY_API + "findAllStores/" + driverId + "/" + driverKey,
        {
          pageNo: 0,
          pageSize: 100,
        }
      );
      if (response.status === 200) {
        this.arrayToList(response.data);
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  arrayToList(stores) {
    let optionGroup = [];
    let optionPay = [];
    let optionAddressType = [];
    for (let i = stores.length - 1; i >= 0; i--) {
      optionGroup.push({ value: stores[i].id, label: stores[i].companyName });
    }
    optionGroup.sort((a, b) => a.label > b.label ? 1 : -1);

    optionPay.push({ value: 'PAID', label: 'Pre-paid' });
    optionPay.push({ value: 'CASH', label: 'Cash' });
    optionPay.push({ value: 'DEBITCARD', label: 'Debit/Credit' });

    optionAddressType.push({ value: 'HOME', label: 'Home' });
    optionAddressType.push({ value: 'WORK', label: 'Work' });
    optionAddressType.push({ value: 'FRIEND', label: 'Friend' });
    optionAddressType.push({ value: 'Other', label: 'Other' });

    this.setState({
      optionGroup,
      optionPay,
      optionAddressType,
      stores,
    });
  }

  //Select
  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  handleSelectPay = (selectedPay) => {
    this.setState({ selectedPay });
  };

  handleSelectAddressType = (selectedAddressType) => {
    Cookies.set('addressType', selectedAddressType.value);
    this.setState({ selectedAddressType });
  };

  handleStay = async (stay) => {
    this.setState({ stay });
  };

  updatePreparationTimeInTimeValue(evt) {
    this.setState({preparationTimeInTime:evt.target.value})
  };

  handleSubmit = async (event, error, values) => {
    this.setState({ error, values });

    if (typeof error?.length !== "undefined" && error?.length === 0) {
      if (values) {
        try {
          let driverKey = sessionStorage.getItem("secretKey");
          let driverId = sessionStorage.getItem("userId");
          if (
            this.state.userRole === "ADMIN" ||
            this.state.userRole === "SUPER_ADMIN"
          ) {
            if (!this.state.selectedGroup || !this.state.selectedGroup.value) {
              toastr.error('Store name is not selected', 'Error');
              return;
            }
            values.storeId = this.state.selectedGroup.value;
          }
          values.toAddressType = this.state.selectedAddressType.value;
          values.toPay = this.state.selectedPay.value;
          if (this.state.preparationTimeInTime != null || this.state.preparationTimeInTime != '') {
            var today = new Date();
            var currentHour = today.getHours()*60;
            var currentMins = today.getMinutes();
            
            var selectedHour = this.state.preparationTimeInTime.split(":")[0]*60;
            var selectedMins = this.state.preparationTimeInTime.split(":")[1];

            var currentTime = Number(currentHour) + Number(currentMins);
            var selectedTime = Number(selectedHour) + Number(selectedMins);
            const minsDiff = selectedTime - currentTime;
            values.preparationTime = !isNaN(minsDiff) ? minsDiff : 0;
          
          }
          values.enterMobileNUmber = null;
          if (!(this.state.toAddressLine1Auto && this.state.toAddressLine1Auto[0] != '')) {
            values.toAddressLine1 = 'GET INFO FROM STORE';
          } else {
            values.toAddressLine1 = 'GET INFO FROM STORE';
            if (this.state.toAddressLine1Auto[0] && this.state.toAddressLine1Auto[0].includes(',')) {
              values.toAddressLine1 = this.state.toAddressLine1Auto[0].split(',')[0];
            } else if (this.state.toAddressLine1Auto[0]) {
              values.toAddressLine1 = this.state.toAddressLine1Auto[0];
            }
          }
          if (values.toPostalCode === 'NA') {
            values.toPostalCode = '';
          }
          if (values.toCity === 'NA') {
            values.toCity = '';
          }
          if (values.toProvince === 'NA') {
            values.toProvince = '';
          }
          if (values.toCountry === 'NA') {
            values.toCountry = '';
          }
          await this.calcDelivery(values, driverId, driverKey);
          this.setState({
            fields: values,
          });
        } catch (error) {
            console.error("Error:", error);
        }
      }
    }
  };

  calcDelivery = async (values, driverId, driverKey) => {
    fetch(
      config.DELIVERY_API + "calcDeliveryCharges/" + driverId + "/" + driverKey,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toastr.error(data.message || data.error, 'Error');
        } else {
          console.log(data);
          this.setState({
            delivery_description: data.description,
            delivery_value: data.value,
          });
          // this.form && this.form.reset();
          this.calcDeliveryModal();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toastr.error("Fail to load", 'Error');
      });
  };

  createOtherPickupOrder = () => {
    this.props.history.push("order-create-nostore");
  };

  createBulkOrder = () => {
    this.props.history.push("order-create-bulk");
  };

  backToDispatch = () => {
    this.props.history.push("ecommerce-orders");
  };

  getDeliveryInfo = async () => {};

  debounce = (func, timeout = 3000) => {
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  };

  getAddressInfoByMobileNumber = async (e) => {
    if (e.target.value && e.target.value != null) {
      this.setState({
        fields: {
          toMobileNumber: e.target.value
        }
      });
    }

    if (e.target.value && e.target.value != null && e.target.value.length > 9) {
      fetch(
        `${config.DELIVERY_API}getAddressInfoAsList/${this.state.driverId}/${this.state.driverKey}/${e.target.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.json())
      .then((response) => {
        let res = [];
        if (response != null) {
          res = response;
        } 
        let data = [];
        if (res) {
          res.forEach((element) => {
            data.push({
              ...element,
              addressLine1: element.deliveryAddress.addressLine1,
              addressLine2: element.deliveryAddress.addressLine2,
              postalCode: element.deliveryAddress.postalCode,
              addressType: element.deliveryAddress.addressType,
              city: element.deliveryAddress.city,
              use: (
                <Button
                  type="button"
                  onClick={() => {
                    this.setState({
                      toAddressLine1Auto:[element.deliveryAddress.addressLine1]
                    });

                    this.setState({
                      fields: {
                        toFirstName: element.deliveryFirstName,
                        //toAddressLine1: element.deliveryAddress.addressLine1,
                        toAddressLine2: element.deliveryAddress.addressLine2,
                        toPostalCode: element.deliveryAddress.postalCode,
                        toCity: element.deliveryAddress.city,
                        toProvince: element.deliveryAddress.province,
                        toLastName: element.deliveryLastName,
                        toMobileNumber: element.deliveryMobileNumber,
                        toAdditionalInfo: element.notes
                      },
                      
                    })
                    if (element.deliveryAddress.addressType == 'HOME') {
                      this.setState({selectedAddressType: { value: 'HOME', label: 'Home' }})
                    }
                    if (element.deliveryAddress.addressType == 'WORK') {
                      this.setState({selectedAddressType: { value: 'WORK', label: 'Work' }})
                    }
                    if (element.deliveryAddress.addressType == 'FRIEND') {
                      this.setState({selectedAddressType: { value: 'FRIEND', label: 'Friend' }})
                    }
                    if (element.deliveryAddress.addressType == 'Other') {
                      this.setState({selectedAddressType: { value: 'Other', label: 'Other' }})
                    }
                  }}
                >
                  Apply
                </Button>
              ),
            });
          });
        }
        this.setState({
          mobile_info: res ? data : response,
          address_info: []
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }
  };

  populateAddressFields = (element) => {

    if (element) {
      this.setState({
        toAddressLine1Auto:element
      });
    }
    if (element && element[0]) {
      var previousFields = this.state.fields;
      this.setState({
        fields: {
          ...previousFields,
          toCity: element[0].split(",")[1],
          toProvince: element[0].split(",")[2],
          toPostalCode: '',
          toAddressLine2: ''
        },
      })
    }
  }

  getAddressInfoByAddressLine = async (value) => {
    if (value && value != null && value.length > 2) {
      fetch(
        `${config.DELIVERY_API}getLocationSuggestionsOnAddress/${this.state.driverId}/${this.state.driverKey}/${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.json())
      .then((response) => {
        let res = [];
        if (response != null) {
          res = response;
        }
        let data = [];
        if (res) {
          res.suggestions.forEach((element) => {
            data.push(element);
          });
        }

        this.setState({
          mobile_info: [],
          address_info: data
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        toastr.error("Enter more than 7 digit", 'Error');
      });
    }
  };

  placeDelivery = async () => {
    fetch(
      config.DELIVERY_API +
        "placeDeliveryOrder/" +
        this.state.driverId +
        "/" +
        this.state.driverKey,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.fields),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          //toastr.success('Order is successfully created.', 'Success');
        } else {
          toastr.error('Service failure, Please contact support team.', 'Error');
        }

        this.calcDeliveryModal();
        this.placeDeliveryModal();

        if (response.status === 200 && !this.state.stay) {
          this.props.history.push("ecommerce-orders");
        }
        if (response.status === 200 && this.state.stay) {
          this.form && this.form.reset();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toastr.error(error, 'Error');
      });
  };

  calcDeliveryModal = () => {
    this.setState((prevState) => {
      return {
        calcDeliveryModal: !prevState.calcDeliveryModal,
      };
    });
  };

  placeDeliveryModal = () => {
    this.setState((prevState) => {
      return {
        placeDeliveryModal: !prevState.placeDeliveryModal,
      };
    });
  };

  render() {
    const { selectedGroup, optionGroup, selectedPay, optionPay, optionAddressType, selectedAddressType } = this.state;

    const classes = this.props.classes;
    
    return (
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm
                      onSubmit={this.handleSubmit}
                      id="editDriverForm"
                      className="needs-validation"
                      ref={(c) => (this.form = c)}
                    >
                      {(this.state.userRole === "ADMIN" ||
                        this.state.userRole === "SUPER_ADMIN") && (
                        <>
                          <Row>
                            <Col md="1">
                              <FormGroup>
                                <Label htmlFor="storeName">Order Info</Label>
                              </FormGroup>
                            </Col>
                            
                          
                            <Col md="2">
                              <FormGroup>
                                <Label htmlFor="toAddressLine1">
                                  ETA to Pickup (in Mins)
                                </Label>
                                <AvField
                                  name="preparationTime"
                                  placeholder="Preparation Time"
                                  type="number"
                                  errorMessage="Enter Preparation Time"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="preparationTime"
                                  value={"0"}
                                  defaultValue={"0"}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="1">
                              Or
                            </Col>
                            <Col md="2">
                              <FormGroup>
                                <Label htmlFor="toAddressLine1">
                                  ETA to Pickup (in Time)
                                </Label>
                                <input className="form-control" 
                                  onChange={evt => this.updatePreparationTimeInTimeValue(evt)}
                                  type="time" defaultValue={this.state.currentDateTime} id="preparationTimeInTime" name="preparationTimeInTime"/>
                              </FormGroup>
                            </Col>
                            <Col>
                              <div className="text-sm-right">
                                <a
                                  className={"btn btn-success"}
                                  onClick={() => this.createOtherPickupOrder()}
                                  role="button"
                                  href="##"
                                >
                                  <i className="bx bx-list-plus"></i> Other pickup location order
                                </a>
                                {" "}
                                <a
                                  className={"btn btn-danger"}
                                  onClick={() => this.createBulkOrder()}
                                  role="button"
                                  href="##"
                                >
                                  <i className="bx bx-upload"></i> Bulk order
                                </a>
                                {" "}
                                <a
                                  className={"btn btn-primary"}
                                  onClick={() => this.backToDispatch()}
                                  role="button"
                                  href="##"
                                >
                                  <i className="bx bx-task"></i> Back to dispatch
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {this.state.userRole === "STORE" && (
                        <>
                          <Row>
                            <Col md="3">
                              <FormGroup>
                                <Label htmlFor="toAddressLine1">
                                  ETA to Pickup (in Mins)
                                </Label>
                                <AvField
                                  name="preparationTime"
                                  placeholder="Preparation Time"
                                  type="number"
                                  errorMessage="Enter Preparation Time"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="preparationTime"
                                  value={"0"}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="1">
                              Or
                            </Col>
                            <Col md="2">
                              <FormGroup>
                                <Label htmlFor="toAddressLine1">
                                  ETA to Pickup (in Time)
                                </Label>
                                <input className="form-control" 
                                  onChange={evt => this.updatePreparationTimeInTimeValue(evt)}
                                  type="time" defaultValue={this.state.currentDateTime} id="preparationTimeInTime" name="preparationTimeInTime"/>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col md="5">
                          <FormGroup className="select2-container">
                            <Label>Store name*</Label>
                            <Select
                              value={selectedGroup}
                              onChange={this.handleSelectGroup}
                              options={optionGroup}
                              validate={{ required: { value: true } }}
                              id="storeName"
                              name="storeName"
                              errorMessage="Enter Store Name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label
                              className="font-weight-bold"
                              style={{ margin: 0, padding: 0, color: 'red' }}
                            >
                              Delivery Info *
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <p className="text-dark font-weight-bold">
                            # We store the delivery information in our system, to
                            help you in retrival of the information based on the
                            mobile number on the next order.
                          </p>
                        </Col>
                      </Row>
                      <Row >
                        <Col md="5">
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md="12" style={{ padding: 0 }}>
                                  <FormGroup>
                                    <Label>Search by delivery mobile number</Label>
                                    <AvField
                                      name="enterMobileNumber"
                                      type="text"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="enterMobileNumber"
                                      onChange={(e) => this.getAddressInfoByMobileNumber(e)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" style={{ padding: 0 }}>
                                  {this.state.mobile_info.length > 0 ?
                                      <MDBDataTable
                                        responsive
                                        bordered
                                        striped
                                        data={{
                                            rows: this.state.mobile_info,
                                            columns: this.state.columns,
                                        }}
                                        entries={100} small noBottomColumns 
                                        searching={false}
                                        displayEntries={false}
                                        paging={false}
                                      /> : <span>No records found</span>
                                  }
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col md="7">
                          <Card outline color="danger" className="border">
                            <CardBody>
                              <Row>
                                <Col md="4">
                                  <FormGroup className="select2-container">
                                    <Label>Address type*</Label>
                                    <Select
                                      value={selectedAddressType}
                                      onChange={this.handleSelectAddressType}
                                      options={optionAddressType}
                                      validate={{ required: { value: true } }}
                                      id="toAddressType"
                                      name="toAddressType"
                                      errorMessage="Enter address type"
                                    />
                                  </FormGroup>
                                </Col>
                              
                                <Col md="4">
                                  <FormGroup>
                                    <Label>First name</Label>
                                    <AvField
                                      name="toFirstName"
                                      placeholder="First name"
                                      type="text"
                                      errorMessage="Enter First name"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toFirstName"
                                      value={this.state.fields.toFirstName}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md="4">
                                  <FormGroup>
                                    <Label>Last name</Label>
                                    <AvField
                                      name="toLastName"
                                      placeholder="Last name"
                                      type="text"
                                      errorMessage="Enter Last name"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toLastName"
                                      value={this.state.fields.toLastName}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <FormGroup>
                                    <AsyncTypeahead
                                      id="toAddressLine1Auto"
                                      labelKey="toAddressLine1Auto"
                                      onSearch={(query) => {this.getAddressInfoByAddressLine(query)}}
                                      onChange={(selected) => {this.populateAddressFields(selected)}}
                                      onFocus={() => {this.setState({toAddressLine1Auto:''})}}
                                      options={this.state.address_info}
                                      placeholder="Address line *"
                                      selected={this.state.toAddressLine1Auto}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                      <AvField
                                        name="toAddressLine2"
                                        placeholder="Apt / Unit number"
                                        type="text"
                                        errorMessage="Enter Apt / Unit number"
                                        className="form-control"
                                        validate={{ required: { value: false } }}
                                        id="toAddressLine2"
                                        value={this.state.fields.toAddressLine2}
                                      />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <AvField
                                      name="toPostalCode"
                                      placeholder="Postal code"
                                      type="text"
                                      errorMessage="Enter postal code"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toPostalCode"
                                      value={this.state.fields.toPostalCode}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <AvField
                                      name="toCity"
                                      placeholder="City"
                                      type="text"
                                      errorMessage="Enter city"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toCity"
                                      value={this.state.fields.toCity}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="2">
                                  <FormGroup>
                                    <AvField
                                      name="toProvince"
                                      placeholder="Province"
                                      type="text"
                                      errorMessage="Enter province"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toProvince"
                                      value={this.state.fields.toProvince}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <FormGroup className="select2-container">
                                    <Label>Payment info*</Label>
                                    <Select
                                      value={selectedPay}
                                      onChange={this.handleSelectPay}
                                      options={optionPay}
                                      validate={{ required: { value: true } }}
                                      id="payment"
                                      name="payment"
                                      errorMessage="Enter payment info"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="select2-container">
                                    <Label>Total amount $</Label>
                                    <AvField
                                      name="toPayAmount"
                                      placeholder="Total amount to be collected"
                                      type="text"
                                      errorMessage="Enter pay amount"
                                      defaultValue="0"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toPayAmount"
                                      value={this.state.fields.toPayAmount}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                    <Label htmlFor="toAdditionalInfo">
                                      Delivery mobile number
                                    </Label>
                                    <AvField
                                      name="toMobileNumber"
                                      placeholder="Delivery mobile number"
                                      type="text"
                                      errorMessage="Enter delivery mobile number"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toMobileNumber"
                                      value={this.state.fields.toMobileNumber}
                                      //onChange={(e) => this.getAddressInfoByMobileNumber(e)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label htmlFor="toAdditionalInfo">
                                      Additional info
                                    </Label>
                                    <AvField
                                      name="toAdditionalInfo"
                                      placeholder="e.g Please use side door, Buzz #1256"
                                      type="text"
                                      errorMessage="Enter Additional info"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="toAdditionalInfo"
                                      value={this.state.fields.toAdditionalInfo}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Button
                                      color="success"
                                      type="submit"
                                      onClick={() => {
                                      this.handleStay(false);
                                      this.handleSubmit();
                                      }}
                                  >
                                      Add Order & Done
                                  </Button>{" "}
                                  <Button
                                      color="warning"
                                      type="submit"
                                      onClick={() => {
                                      this.handleStay(true);
                                      this.handleSubmit();
                                      }}
                                  >
                                      Add Order & Stay
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      
                    </AvForm>
                  </CardBody>
                </Card>

                <Modal
                  isOpen={this.state.calcDeliveryModal}
                  toggle={this.calcDeliveryModal}
                  className="modal-dialog modal-dialog-centered"
                >
                  <ModalHeader toggle={this.calcDeliveryModal}>
                    Are you sure you want to add new Order ?
                  </ModalHeader>
                  <ModalBody>
                    The delivery charges is{" "}
                    {Number(this.state.delivery_value).toFixed(2)} for distance
                    of {this.state.delivery_description} approx. Please press
                    'Ok' to place the Order or click 'Cancel'
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await this.placeDelivery();
                      }}
                    >
                      Ok
                    </Button>{" "}
                    <Button color="secondary" onClick={this.calcDeliveryModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCreate;
