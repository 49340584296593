import React, { Component } from "react";
import { Row, Col, CardBody, Card, Container, Label, CardTitle, FormGroup, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
  registerShop,
} from "../../store/actions";

import logoLight from "../../assets/images/logo.png";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class ShopRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.registerShop(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header" style={{height:"40px"}}>
            <div className="d-flex">
              <div className="navbar-brand-box" style={{height:"40px", paddingLeft:10}}>
                <Link to="/">
                  <span>
                    <img src={logoLight} alt="" height="40px" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
          </div>
        </header>
        <div className="pt-5">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Shop registration</CardTitle>
                    <AvForm
                          onValidSubmit={this.handleValidSubmit} className="outer-repeater">
                      <Row>
                          <Col lg={7}>
                          <div data-repeater-list="outer-group" className="outer">
                            <div data-repeater-item className="outer">
                              <FormGroup className="mb-1" row>
                                  <Label htmlFor="taskname" className="col-form-label col-lg-2">Company name</Label>
                                  <Col lg="10">
                                    <AvField
                                      name="nameofBusiness"
                                      type="text"
                                      required
                                      placeholder="... London Grills"
                                    />
                                  </Col>
                              </FormGroup>
                            
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Contact Name</Label>
                                <Col lg="3">
                                  <AvField
                                    name="contactName"
                                    type="text"
                                    required
                                    placeholder="Name"
                                  />
                                </Col>
                                
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Email</Label>
                                <Col lg="3">
                                  <AvField
                                    name="email"
                                    className="form-control"
                                    placeholder="Email"
                                    type="email"
                                    required
                                  />
                                </Col>
                              </FormGroup>
                            
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Type of business</Label>
                                <Col lg="10">
                                  <AvField
                                    name="typeofBusiness"
                                    type="text"
                                    required
                                    placeholder="... Food / Parcel / Flowers"
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Phone number</Label>
                                <Col lg="10">
                                  <AvField
                                    name="phoneNumber"
                                    type="text"
                                    required
                                    placeholder="... 5191234567"
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Are you currently with any other apps?</Label>
                                <Col lg="10">
                                  <AvField
                                    name="alreadyRegistered"
                                    type="text"
                                    placeholder="... Uber Eats / Skip the dish / Door dash"
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Best time to contact you</Label>
                                <Col lg="10">
                                  <AvField
                                    name="bestTimeToContact"
                                    type="text"
                                    required
                                    placeholder="... 2-4 pm"
                                  />
                                </Col>
                              </FormGroup>
                            </div>
                          </div>
                        </Col>
                      
                        <Col lg={5}>
                          <div data-repeater-list="outer-group" className="outer">
                            <div data-repeater-item className="outer">
                              

                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Apt / Unit number</Label>
                                <Col lg="10">
                                  <AvField
                                    name="addressLine2"
                                    type="text"
                                    placeholder="Apt / Unit number"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Street address</Label>
                                <Col lg="10">
                                  <AvField
                                    name="addressLine1"
                                    type="text"
                                    required
                                    placeholder="Street address"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">City</Label>
                                <Col lg="10">
                                  <AvField
                                    name="city"
                                    type="text"
                                    required
                                    placeholder="City"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Province</Label>
                                <Col lg="10">
                                  <AvField
                                    name="province"
                                    type="text"
                                    required
                                    placeholder="Province"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-1" row>
                                <Label htmlFor="taskname" className="col-form-label col-lg-2">Postal code</Label>
                                <Col lg="10">
                                  <AvField
                                    name="postalCode"
                                    type="text"
                                    required
                                    placeholder="Postal Code"
                                  />
                                </Col>
                              </FormGroup>

                              
                          
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary">Submit</Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Dev Trac. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by Dev Trac
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    registerUser,
    apiError,
    registerUserFailed,
    registerShop,
  })(ShopRegister)
);
