import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Progress,
  Button,
  Card,
  CardBody, CardText,
  Table,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardHeader,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import KeyMarvel from "../../helpers/keymarvel";
import { Fragment } from "react";

import { messaging } from "firebase";
import Map from "../../components/Common/Map";
import Clock from "../../components/Common/Clock";
import PopupMenu from "../../components/CommonForBoth/TopbarDropdown/PopupMenu";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import StarRatings from "react-star-ratings";
import PopupMenuMobile from "../../components/CommonForBoth/TopbarDropdown/PopupMenuMobile";
import API from "../../config.json";
import Axios from "axios";

class EcommerceOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            newTransactions: [],
            transaction: {},
            modal: false,
            groupOrderModal: false,
            newOrderModal: false,
            subOrderModal: false,
            acknowledgeOrderModal: false,
            cancelOrderModal: false,
            completeOrderModal: false,
            availableDrivers: [],
            driverConfirmModal: false,
            assignDriver: false,
            currentModal: {
                orderId: 0,
                driverId: "",
            },
            selectedTab: "today",
            messageBody: "",
            messageTitle: "Title",
            userRole: "",
            companyName: "",
            labels: {
                1: "5 min",
                2: "10 min",
                3: "15 min",
                4: "20 min",
                5: "25 min"
            },
            min_max: 0,
            tableAccordion: {},
            highlightOrder: {},
            selectedOrders: [],
            lookType: 'version2',
            isChecked: true,
            resetSelectedOrders: false,
            mapCenter: {},
        };
        this.togglemodal.bind(this);
        this.toggleGroupOrderModal.bind(this);
        this.toggleNewOrderModal.bind(this);
        this.toggleSubOrderModal.bind(this);
    }

    componentDidMount = async () => {
        var userRoleInSession = sessionStorage.getItem("userRole");
        var companyNameInSession = sessionStorage.getItem("companyName");
        var lookTypeInSession = sessionStorage.getItem("lookType") ? sessionStorage.getItem("lookType") : 'version2';
        this.setState({
            userRole: userRoleInSession,
            companyName: companyNameInSession,
            lookType: lookTypeInSession,
            isChecked: lookTypeInSession == 'version2'
        });

        this.getTransactions();
        this.getDriverResponseTime();
        this.interval = setInterval(() => this.getTransactions(), 10000);

        this.getCenter();

        if (messaging.isSupported()) {
            this.messageListener = messaging().onMessage(async (message) => {

                this.getTransactions();
                if (message.notification.title === "NEW ORDER") {
                    this.setState({
                        messageBody: message.notification.body,
                        messageTitle: message.notification.title,
                        newOrderModal: true,
                    });
                }
            });
        }
    };

    componentWillUnmount() {
        clearTimeout(this.interval);
    }

    getCenter = async () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");

        const result = await Axios.get(
          API.DELIVERY_API + "getMapCenterInfo/" + driverId + "/" + driverKey,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).catch((error) => console.log(error.message));
        if (result) {
          //console.log(result.data?.location);
          
          this.setState({mapCenter: {
            lat: result.data?.location[0],
            lng: result.data?.location[1],
          }});
        }
      };

    getTodays = async () => {
        this.setState({
            selectedTab: "today",
        });
        await this.getTodaysTransactions();
    };

    sendSelectedOrders = async (selectedOrders) => {
        this.setState({
            selectedOrders: selectedOrders,
        });
    };

    createOrder = async () => {
        this.setState({
        selectedTab: "today",
        });
        this.props.history.push("order-create");
    };

    groupOrder = async () => {
        this.toggleGroupOrderModal();
    };

    createBulkOrder = async () => {
        this.setState({ selectedTab: "today" });
        this.props.history.push("order-create-bulk");
    };

    getPast = async () => {
        this.setState({
        selectedTab: "history",
        });
        await this.getPastTransactions();
    };

    getTodaysTransactions = async () => {
        let apiName = "getTodaysActiveOrders";
        await KeyMarvel.getTransactions(apiName).then((result) => {
        if (result && result.status === 200) {
            this.setState({
                transactions: result.data.content,
            });

            //this.intervalId = setTimeout(this.getTransactions('today').bind(this), 1200000);
        } else {
            console.error("error" + JSON.stringify("Failed to connect"));
        }
        });
    };

    getPastTransactions = async () => {
        let apiName = "getPastOrders";
        await KeyMarvel.getTransactions(apiName).then((result) => {
            if (result && result.status === 200) {
                this.setState({
                    transactions: result.data.content,
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
        });
    };

    getTransactions = async () => {
        this.setState({
            highlightOrder: {},
        });
        
        let apiName =
        this.state.selectedTab === "today"
        ? "getTodaysActiveOrders"
        : "getPastOrders";
        await KeyMarvel.getTransactions(apiName).then((result) => {
            if (result && result.status === 200) {
                this.setState({
                    transactions: result.data.content,
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
        });

        if (this.state.userRole === "SUPER_ADMIN") {
            await KeyMarvel.getActiveDrivers().then((result) => {
                if (result && result.status === 200) {
                    var data = result.data;
                    this.setState({
                        availableDrivers: data,
                    });
                } else {
                    console.error("error" + JSON.stringify("Failed to connect"));
                }
            });
        }

        if (this.state.selectedTab === "today") {
            await KeyMarvel.getResponseTime().then((result) => {
                if (result && result.status === 200) {
                    var data = result.data;
                    this.setState({
                        min_max: data,
                    });
                } else {
                    console.log("error" + JSON.stringify("Failed to connect"));
                }
            });
        }
    };

    getActiveDrivers = async () => {
        console.log("fetch drivers", this.state.userRole);
        if (this.state.selectedTab === "today" && this.state.userRole === "SUPER_ADMIN") {
            console.log("fetch drivers s", this.state.userRole);
            await KeyMarvel.getActiveDrivers().then((result) => {
            if (result && result.status === 200) {
                var data = result.data;
                this.setState({
                availableDrivers: data,
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
            });
        }
    };

    getDriverResponseTime = async () => {
        if (this.state.selectedTab === "today") {
            await KeyMarvel.getResponseTime().then((result) => {
                if (result && result.status === 200) {
                    var data = result.data;
                    this.setState({
                        min_max: data,
                    });
                } else {
                    console.log("error" + JSON.stringify("Failed to connect"));
                }
            });
        }
    };

    formatDate(string) {
        var options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(string).toLocaleDateString([], options);
    }

    formatTime(string) {
        return new Date(string).toLocaleTimeString();
    }

    async showModal(order) {
        await this.getActiveDrivers();

        this.setState({
            transaction: order,
        });
        this.togglemodal();
    }

    async highlightOrder(order) {
        this.setState({
            highlightOrder: {...order},
        });
    }

    showAcknowledgeOrderModal(order) {
        this.setState({
            transaction: order,
            currentModal: {
                orderId: order.id,
            },
        });
        this.toggleAcknowledgeOrderModal();
    }

    toggleVisionType = () => {
        var lookType = this.state.isChecked ? 'version1' : 'version2';
        this.setState((state) => ({isChecked: !state.isChecked, lookType}));
        sessionStorage.setItem("lookType", lookType);
    };

    showCancelOrderModal(order) {
        this.setState({
            transaction: order,
            currentModal: {
            orderId: order.id,
            },
        });
        this.toggleCancelOrderModal();
    }

    showSubOrderModal(order) {
        this.setState({
            transaction: order,
            currentModal: {
                orderId: order.id,
            },
        });
        this.toggleSubOrderModal();
    }

    showCompleteOrderModal(order) {
        this.setState({
            transaction: order,
            currentModal: {
            orderId: order.id,
            },
        });
        this.toggleCompleteOrderModal();
    }

    togglemodal = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    toggleGroupOrderModal = () => {
        this.setState((prevState) => ({
            groupOrderModal: !prevState.groupOrderModal,
        }));
    };

    toggleSubOrderModal = () => {
        this.setState((prevState) => ({
            subOrderModal: !prevState.subOrderModal,
        }));
    };

    toggleNewOrderModal = () => {
        this.setState((prevState) => ({
            newOrderModal: !prevState.newOrderModal,
        }));
    };

    toggleAcknowledgeOrderModal = () => {
        this.setState((prevState) => ({
            acknowledgeOrderModal: !prevState.acknowledgeOrderModal,
        }));
    };

    toggleCancelOrderModal = () => {
        this.setState((prevState) => ({
            cancelOrderModal: !prevState.cancelOrderModal,
        }));
    };

    toggleCompleteOrderModal = () => {
        this.setState((prevState) => ({
            completeOrderModal: !prevState.completeOrderModal,
        }));
    };

    formatPrice(string) {
        return new Intl.NumberFormat("en-CA", {
            style: "currency",
            currency: "CAD",
        }).format(string);
    }

    acknowledgeOrder = async () => {
        await KeyMarvel.acknowledgeOrder(this.state.currentModal.orderId).then(
            (result) => {
            if (result && result.status === 200) {
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
            }
        );
        this.onRefresh("today");
    };

    cancelOrder = async () => {
        await KeyMarvel.cancelOrder(this.state.currentModal.orderId).then(
            (result) => {
            if (result && result.status === 200) {
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
            }
        );
        this.onRefresh("today");
        };

        completeOrder = async () => {
            await KeyMarvel.completeOrder(this.state.currentModal.orderId).then(
            (result) => {
                if (result && result.status === 200) {
                } else {
                    console.error("error" + JSON.stringify("Failed to connect"));
                }
            }
        );
        this.onRefresh("today");
    };

    updateResponseTime = async (event) => {
        await KeyMarvel.updateResponseTime(event).then((result) => {
            if (result && result.status === 200) {
                this.onRefresh("today");
            } else {
                console.log("error " + JSON.stringify("Failed to connect"));
            }
        });
    };

    selectDriverInGroup = async (event, driverId) => {
        if (this.state.availableDrivers.length > 0) {
            await KeyMarvel.updateDriverInGroupOrder(this.state.selectedOrders, driverId).then((result) => {
            if (result && result.status === 200) {
                this.setState({
                    selectedDriver: [],
                    selectedOrders: [],
                    resetSelectedOrders: true
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
            });
            this.onRefresh("today");
        }
    };

    selectDriver = async (event, orderId, driverId) => {
        if (this.state.availableDrivers.length > 0) {
            await KeyMarvel.updateDriver(orderId, driverId).then((result) => {
            if (result && result.status === 200) {
                this.setState({
                    selectedDriver: [],
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
            });
            this.onRefresh("today");
        }
    };

    deselectDriver = async (orderId) => {
        await KeyMarvel.deselectDriver(orderId).then((result) => {
            if (result && result.status === 200) {
            this.setState({
                selectedTab: "today",
            });
            } else {
            console.error("error" + JSON.stringify("Failed to connect"));
            }
        });
        this.onRefresh();
    };

    handleAcknowledgeOrderConfirmModalClose = () => {
        this.setState({
            acknowledgeOrderModal: false,
        });
    };

    handleAcknowledgeOrderConfirmModalAccept = () => {
        this.setState({
            acknowledgeOrderModal: false,
        });
        this.acknowledgeOrder();
    };

    handleCancelOrderConfirmModalClose = () => {
        this.setState({
            cancelOrderModal: false,
        });
    };

    handleCancelOrderConfirmModalAccept = () => {
        this.setState({
            cancelOrderModal: false,
        });
        this.cancelOrder();
    };

    handleCompleteOrderConfirmModalClose = () => {
        this.setState({
            completeOrderModal: false,
        });
    };

    handleCompleteOrderConfirmModalAccept = () => {
        this.setState({
            completeOrderModal: false,
        });
        this.completeOrder();
    };

    onRefresh = async () => {
        this.setState({
            loading: true,
            modal: false, groupOrderModal: false
        });

        await this.getTransactions();
        await this.getActiveDrivers();
        await this.getDriverResponseTime();
    };

    render() {
        return (
            <React.Fragment>
                
                {this.state.lookType == 'version2' && 
                    <div className="page-content" style={{margin:0, paddingLeft:0, paddingBottom:0, paddingTop: 45, paddingRight:0}}>
                        <Container fluid>
                            <Row style={{padding:"0px"}}>
                                
                                

                                <Col style={{padding:"0px"}} md="3">
                                    
                                    <Card style={{margin:"0px"}}>
                                        <CardBody style={{padding:"0px"}}>
                                            <div className="mb-1" style={{padding:"5px", overflowY: "scroll", height: "760px"}}>
                                                <Row style={{marginTop:"10px", marginBottom:"10px"}}>
                                                    <Col md="12"><span className="avatar-title bg-danger text-dark">New Orders</span></Col>
                                                </Row>
                                                <Row style={{padding:"0px"}}>
                                                    {this.state.transactions.length > 0 &&
                                                        this.state.transactions.map((order, key) => {
                                                            if (order.deliveryStatus == 'DELIVER_ASSIGN' ||
                                                                order.deliveryStatus == 'DELIVER_ACCEPT'
                                                            ) {
                                                                return (
                                                                    <>
                                                                        {!!!order.parentOrderId && (
                                                                            <Fragment key={"order_" + key}>
                                                                                <Col md="12">
                                                                                    <Card style={{marginLeft: "1px", marginRight: "1px", marginBottom:"2px", padding: 0, border:"1px solid" }}
                                                                                            color={this.state.selectedOrders.includes(order.id) ? "danger" : this.state.highlightOrder?.id == order.id ? "warning" : order.formatDate && order.formatDate.substring(12) !== order.storeETATime ? "primary" : order.subOrders &&
                                                                                                order.subOrders.length > 0 ? "success" : order.status === "CANCELLED" ? "danger" : "info"
                                                                                            } 
                                                                                            onClick={() => {
                                                                                                this.highlightOrder(order);
                                                                                            }} outline>
                                                                                    <CardBody style={{margin: 0, padding: 3}}>
                                                                                        <CardHeader style={{margin: 0, padding: 0}}>
                                                                                            {order.colorCodeReason && 
                                                                                                <>
                                                                                                    <i
                                                                                                        className="dripicons-warning font-size-13 align-middle" style={{color:"red"}}
                                                                                                        title={
                                                                                                            order.colorCodeReason
                                                                                                                ? order.colorCodeReason
                                                                                                                : ""
                                                                                                        }
                                                                                                    ></i>
                                                                                                </>
                                                                                            }
                                                                                            {order.subOrders && order.subOrders.length > 0 && (
                                                                                                <>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            margin: 0,
                                                                                                            padding: 0
                                                                                                        }}
                                                                                                        className="font-size-13 font-weight-bold"
                                                                                                        >
                                                                                                        {order.id} x
                                                                                                        {order.subOrders.length + 1}
                                                                                                    </span>
                                                                                                </>
                                                                                            )}
                                                                                            {!(order.subOrders && order.subOrders.length > 0) && (
                                                                                                <span
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        padding: 0
                                                                                                    }}
                                                                                                    className="font-size-13 "
                                                                                                    >
                                                                                                    {order.id}
                                                                                                </span>
                                                                                            )}

                                                                                            <span style={{margin: 0, padding: 0}}
                                                                                                        className={"font-size-12 font-weight-bold ml-1"}>
                                                                                                {order.formatDate && (order.formatDate.substring(12) === order.storeETATime) && 
                                                                                                    <>ASAP</>
                                                                                                }
                                                                                                {order.formatDate && (order.formatDate.substring(12) !== order.storeETATime) && 
                                                                                                <>{order.storeETATime}</>
                                                                                                }
                                                                                            </span>
                                                                                            

                                                                                            <span style={{margin: 0, padding: 0, float:'right'}} className="font-size-13">
                                                                                                {order.formatDate && order.formatDate.substring(11)}{"  "}

                                                                                                {this.state.selectedTab === "today" &&
                                                                                                !(order.status == 'HOLD' || order.status == 'SUBMITTED') &&
                                                                                                (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Complete order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showCompleteOrderModal(
                                                                                                                order
                                                                                                            )
                                                                                                        }
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-check-circle"></i>
                                                                                                    </Link>
                                                                                                    
                                                                                                )}
                                                                                                {this.state.selectedTab === "today" 
                                                                                                    && (order.status == "HOLD" || order.status == "SUBMITTED") 
                                                                                                    && order.id.startsWith("BOX") && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Acknowledge box order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showAcknowledgeOrderModal(order)
                                                                                                        } 
                                                                                                        className="text-body text-success font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-check-circle text-success"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                                {this.state.selectedTab === "today" &&
                                                                                                    <Link
                                                                                                        to={{
                                                                                                        pathname: "/order-edit",
                                                                                                        state: order.id,
                                                                                                        }}
                                                                                                        id={"edit-order" + order.id} title="Edit Order" className="text-body font-size-16">
                                                                                                        <i className="bx bxs-wrench text-primary"></i>
                                                                                                    </Link>
                                                                                                }
                                                                                                {this.state.selectedTab === "today" && (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Cancel order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showCancelOrderModal(order)
                                                                                                        }
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-x-circle"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                                {order.subOrders && order.subOrders.length > 0 && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Cancel order"
                                                                                                        to="#"
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                tableAccordion: {
                                                                                                                    ...this.state.tableAccordion,
                                                                                                                    [order.id]:
                                                                                                                    !!!this.state.tableAccordion[
                                                                                                                        order.id
                                                                                                                    ],
                                                                                                                },
                                                                                                            });
                                                                                                            
                                                                                                            this.showSubOrderModal(order);
                                                                                                        }}
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-expand"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                            </span>
                                                                                        </CardHeader>

                                                                                        <CardText style={{margin: 0, padding: 0}}>
                                                                                            {order.deliveryStatus && order.driverName && order.subOrders?.length > 0 && (
                                                                                                <span style={{margin: 0, padding: 0}}
                                                                                                        className={"font-size-12 font-weight-bold"}
                                                                                                    >
                                                                                                    {order.driverName}
                                                                                                </span>
                                                                                            )}
                                                                                            {this.state.selectedTab ===
                                                                                                "today" &&
                                                                                                order.deliveryStatus &&
                                                                                                order.deliveryStatus !==
                                                                                                "DELIVER_COMPLETED" && order.driverName && (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin: 0, padding: 0}} 
                                                                                                        data-toggle="tooltip"
                                                                                                        color="danger"
                                                                                                        data-placement="bottom"
                                                                                                        title="Unassign driver"
                                                                                                        onClick={(e) =>
                                                                                                            this.showModal(order)
                                                                                                        }
                                                                                                        to="#"
                                                                                                        className="text-body"
                                                                                                    >
                                                                                                        <span style={{margin: 0, padding: 0, textDecoration: "underline"}}
                                                                                                            className="font-size-14 text-info"
                                                                                                        >
                                                                                                            {order.driverName}
                                                                                                        </span>
                                                                                                    </Link>
                                                                                            )}
                                                                                            {this.state.selectedTab ===
                                                                                                "today" && !(order.status == 'HOLD' || order.status == 'SUBMITTED') &&
                                                                                                order.deliveryStatus &&
                                                                                                order.deliveryStatus !==
                                                                                                "DELIVER_COMPLETED" && !order.driverName && (
                                                                                                <Link style={{margin: 0, padding: 0}} 
                                                                                                    data-toggle="tooltip"
                                                                                                    color="danger"
                                                                                                    data-placement="bottom"
                                                                                                    title="Assign driver"
                                                                                                    onClick={(e) =>
                                                                                                        this.showModal(order)
                                                                                                    }
                                                                                                    to="#"
                                                                                                    className="text-body"
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            padding: "0px",
                                                                                                            margin: "0px", textDecoration:'underline'
                                                                                                        }}
                                                                                                        className="font-size-14 text-info"
                                                                                                    >
                                                                                                        {"NA"}
                                                                                                    </span>
                                                                                                </Link>
                                                                                            )}
                                                                                            {order.colorCodeReason && 
                                                                                                <>
                                                                                                    <i
                                                                                                        className="dripicons-warning font-size-13 align-middle ml-1" style={{color:"red"}}
                                                                                                        title={
                                                                                                            order.colorCodeReason
                                                                                                                ? order.colorCodeReason
                                                                                                                : ""
                                                                                                        }
                                                                                                    ></i>
                                                                                                    <span
                                                                                                        style={{margin: 0, padding: 0, color:"red"}}
                                                                                                        className="font-size-13"
                                                                                                    >
                                                                                                        {" "}{order.colorCodeReason && order.colorCodeReason}
                                                                                                    </span>
                                                                                                </>
                                                                                            }

                                                                                            <span
                                                                                                style={{margin: 0, padding: 0}}
                                                                                                className="font-size-12 font-weight-bold"
                                                                                            >{" | "}</span>

                                                                                            <span
                                                                                                style={{margin: 0, padding: 0}}
                                                                                                className="font-size-12 text-danger"
                                                                                            >
                                                                                                {order.deliveryStatus
                                                                                                    ? order.deliveryStatus ===
                                                                                                        "DELIVER_ASSIGN"
                                                                                                        ? "Assigned"
                                                                                                        : order.deliveryStatus ===
                                                                                                            "DELIVER_ACCEPT"
                                                                                                            ? "Accept"
                                                                                                            : order.deliveryStatus ===
                                                                                                                "DELIVER_ONTOSTORE"
                                                                                                                ? "On route"
                                                                                                                : order.deliveryStatus ===
                                                                                                                    "DELIVER_REACHEDSTORE"
                                                                                                                    ? "Arrived"
                                                                                                                    : order.deliveryStatus ===
                                                                                                                        "DELIVER_LEFTSTORE"
                                                                                                                        ? "Left store"
                                                                                                                        : order.deliveryStatus ===
                                                                                                                            "DELIVER_ONTOCLIENT"
                                                                                                                            ? "Way to drop"
                                                                                                                            : order.deliveryStatus ===
                                                                                                                                "DELIVER_COMPLETED"
                                                                                                                                ? "Clr"
                                                                                                                                : "NA"
                                                                                                    : "NA"}
                                                                                            </span>

                                                                                            <span style={{margin: 0, padding: 0}} className="font-size-12">{" | "}{order.deliveryAddress && order.deliveryAddress.addressLine1}</span>
                                                                                        
                                                                                        
                                                                                            <span className="font-size-12 font-weight-bold">{" | "}{order.customerETATime}</span><span style={{margin: 0, padding: 0, float:"right"}} className="font-size-12 text-dark">{order.storeName && order.storeName.substring(0, 14)}</span>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Fragment>
                                                                        )}
                                                                    </>
                                                                );
                                                            }
                                                        })}
                                                    {this.state.newTransactions.length <= 0 && (
                                                        <Fragment>
                                                            <div scope="row" style={{margin:0, paddingLeft: 10}}>
                                                                <div style={{margin:0, padding:0}}>No records found</div>
                                                            </div>
                                                        </Fragment>
                                                    )}

                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                        
                                </Col>

                                <Col md="6">
                                    <Map highlightOrder={this.state.highlightOrder} sendSelectedOrders={this.sendSelectedOrders} lookType={this.state.lookType} resetSelectedOrders={this.state.resetSelectedOrders} mapCenter={this.state.mapCenter} />
                                </Col>

                                <Col style={{padding:"0px"}} md="3">
                                    
                                    <Card style={{margin:"0px"}}>
                                        <CardBody style={{padding:"0px"}}>
                                            <div style={{marginLeft:'10px'}} className="text-sm-center">
                                                <a
                                                    className={
                                                        "ml-2 font-size-14 btn btn-sm " +
                                                        (this.state.selectedTab === "today"
                                                            ? "btn-primary mr-4"
                                                            : "btn-outline-primary mr-4")
                                                    }
                                                    onClick={() => this.createOrder()}
                                                    role="button"
                                                >
                                                    <i className="bx bx-list-plus "></i> Add order
                                                </a>

                                                <a
                                                    className={
                                                        "ml-2 font-size-14 btn btn-sm " +
                                                        (this.state.selectedTab === "today"
                                                            ? "btn-warning mr-4"
                                                            : "btn-outline-warning mr-4")
                                                    }
                                                    onClick={() => this.groupOrder()}
                                                    role="button"
                                                >
                                                    <i className="bx bx-checkbox "></i> Group order
                                                </a>
                                            </div>
                                            
                                            
                                            <div className="mb-1" style={{padding:"5px", overflowY: "scroll", height: "730px"}}>
                                                <Row style={{marginTop:"10px", marginBottom:"10px"}}>
                                                    <Col md="12"><span className="avatar-title bg-success text-dark">On the way to delivery - Orders</span></Col>
                                                </Row>
                                                <Row style={{padding:"0px"}}>
                                                    {this.state.transactions.length > 0 &&
                                                        this.state.transactions.map((order, key) => {
                                                            if (order.deliveryStatus == 'DELIVER_ONTOSTORE' ||
                                                                order.deliveryStatus == 'DELIVER_REACHEDSTORE' || 
                                                                order.deliveryStatus == 'DELIVER_LEFTSTORE' ||
                                                                order.deliveryStatus == 'DELIVER_ONTOCLIENT' ||
                                                                order.deliveryStatus == 'DELIVER_COMPLETED'
                                                            ) {
                                                                return (
                                                                    <>
                                                                        {!!!order.parentOrderId && (
                                                                            <Fragment key={"order_" + key}>
                                                                                <Col md="12">
                                                                                    <Card style={{marginLeft: "1px", marginRight: "1px", marginBottom:"2px", padding: 0, border:"1px solid" }}
                                                                                            color={this.state.selectedOrders.includes(order.id) ? "danger" : this.state.highlightOrder?.id == order.id ? "warning" : order.formatDate && order.formatDate.substring(12) !== order.storeETATime ? "primary" : order.subOrders &&
                                                                                                order.subOrders.length > 0 ? "success" : order.status === "CANCELLED" ? "danger" : "info"
                                                                                            } 
                                                                                            onClick={() => {
                                                                                                this.highlightOrder(order);
                                                                                            }} outline>
                                                                                    <CardBody style={{margin: 0, padding: 3}}>
                                                                                        <CardHeader style={{margin: 0, padding: 0}}>
                                                                                            {order.colorCodeReason && 
                                                                                                <>
                                                                                                    <i
                                                                                                        className="dripicons-warning font-size-13 align-middle" style={{color:"red"}}
                                                                                                        title={
                                                                                                            order.colorCodeReason
                                                                                                                ? order.colorCodeReason
                                                                                                                : ""
                                                                                                        }
                                                                                                    ></i>
                                                                                                </>
                                                                                            }
                                                                                            {order.subOrders && order.subOrders.length > 0 && (
                                                                                                <>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            margin: 0,
                                                                                                            padding: 0
                                                                                                        }}
                                                                                                        className="font-size-13 font-weight-bold"
                                                                                                        >
                                                                                                        {order.id} x
                                                                                                        {order.subOrders.length + 1}
                                                                                                    </span>
                                                                                                </>
                                                                                            )}
                                                                                            {!(order.subOrders && order.subOrders.length > 0) && (
                                                                                                <span
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        padding: 0
                                                                                                    }}
                                                                                                    className="font-size-13 "
                                                                                                    >
                                                                                                    {order.id}
                                                                                                </span>
                                                                                            )}

                                                                                            <span style={{margin: 0, padding: 0}}
                                                                                                        className={"font-size-12 font-weight-bold ml-1"}>
                                                                                                {order.formatDate && (order.formatDate.substring(12) === order.storeETATime) && 
                                                                                                    <>ASAP</>
                                                                                                }
                                                                                                {order.formatDate && (order.formatDate.substring(12) !== order.storeETATime) && 
                                                                                                <>{order.storeETATime}</>
                                                                                                }
                                                                                            </span>
                                                                                            

                                                                                            <span style={{margin: 0, padding: 0, float:'right'}} className="font-size-13">
                                                                                                {order.formatDate && order.formatDate.substring(11)}{"  "}

                                                                                                {this.state.selectedTab === "today" &&
                                                                                                !(order.status == 'HOLD' || order.status == 'SUBMITTED') &&
                                                                                                (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Complete order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showCompleteOrderModal(
                                                                                                                order
                                                                                                            )
                                                                                                        }
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-check-circle"></i>
                                                                                                    </Link>
                                                                                                    
                                                                                                )}
                                                                                                {this.state.selectedTab === "today" 
                                                                                                    && (order.status == "HOLD" || order.status == "SUBMITTED") 
                                                                                                    && order.id.startsWith("BOX") && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Acknowledge box order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showAcknowledgeOrderModal(order)
                                                                                                        } 
                                                                                                        className="text-body text-success font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-check-circle text-success"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                                {this.state.selectedTab === "today" &&
                                                                                                    <Link
                                                                                                        to={{
                                                                                                        pathname: "/order-edit",
                                                                                                        state: order.id,
                                                                                                        }}
                                                                                                        id={"edit-order" + order.id} title="Edit Order" className="text-body font-size-16">
                                                                                                        <i className="bx bxs-wrench text-primary"></i>
                                                                                                    </Link>
                                                                                                }
                                                                                                {this.state.selectedTab === "today" && (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Cancel order"
                                                                                                        to="#"
                                                                                                        onClick={() =>
                                                                                                            this.showCancelOrderModal(order)
                                                                                                        }
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-x-circle"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                                {order.subOrders && order.subOrders.length > 0 && (
                                                                                                    <Link style={{margin:0, padding:0}}
                                                                                                        data-toggle="tooltip"
                                                                                                        data-placement="bottom"
                                                                                                        title="Cancel order"
                                                                                                        to="#"
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                tableAccordion: {
                                                                                                                    ...this.state.tableAccordion,
                                                                                                                    [order.id]:
                                                                                                                    !!!this.state.tableAccordion[
                                                                                                                        order.id
                                                                                                                    ],
                                                                                                                },
                                                                                                            });
                                                                                                            
                                                                                                            this.showSubOrderModal(order);
                                                                                                        }}
                                                                                                        className="text-body font-weight-bold font-size-16"
                                                                                                    >
                                                                                                        <i className="bx bx-expand"></i>
                                                                                                    </Link>
                                                                                                )}
                                                                                            </span>
                                                                                        </CardHeader>

                                                                                        <CardText style={{margin: 0, padding: 0}}>
                                                                                            {order.deliveryStatus && order.driverName && order.subOrders?.length > 0 && (
                                                                                                <span style={{margin: 0, padding: 0}}
                                                                                                        className={"font-size-12 font-weight-bold"}
                                                                                                    >
                                                                                                    {order.driverName}
                                                                                                </span>
                                                                                            )}
                                                                                            {this.state.selectedTab ===
                                                                                                "today" &&
                                                                                                order.deliveryStatus &&
                                                                                                order.deliveryStatus !==
                                                                                                "DELIVER_COMPLETED" && order.driverName && (order.subOrders == null || order.subOrders?.length <= 0) && (
                                                                                                    <Link style={{margin: 0, padding: 0}} 
                                                                                                        data-toggle="tooltip"
                                                                                                        color="danger"
                                                                                                        data-placement="bottom"
                                                                                                        title="Unassign driver"
                                                                                                        onClick={(e) =>
                                                                                                            this.showModal(order)
                                                                                                        }
                                                                                                        to="#"
                                                                                                        className="text-body"
                                                                                                    >
                                                                                                        <span style={{margin: 0, padding: 0, textDecoration: "underline"}}
                                                                                                            className="font-size-14 text-info"
                                                                                                        >
                                                                                                            {order.driverName}
                                                                                                        </span>
                                                                                                    </Link>
                                                                                            )}
                                                                                            {this.state.selectedTab ===
                                                                                                "today" && !(order.status == 'HOLD' || order.status == 'SUBMITTED') &&
                                                                                                order.deliveryStatus &&
                                                                                                order.deliveryStatus !==
                                                                                                "DELIVER_COMPLETED" && !order.driverName && (
                                                                                                <Link style={{margin: 0, padding: 0}} 
                                                                                                    data-toggle="tooltip"
                                                                                                    color="danger"
                                                                                                    data-placement="bottom"
                                                                                                    title="Assign driver"
                                                                                                    onClick={(e) =>
                                                                                                        this.showModal(order)
                                                                                                    }
                                                                                                    to="#"
                                                                                                    className="text-body"
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            padding: "0px",
                                                                                                            margin: "0px", textDecoration:'underline'
                                                                                                        }}
                                                                                                        className="font-size-14 text-info"
                                                                                                    >
                                                                                                        {"NA"}
                                                                                                    </span>
                                                                                                </Link>
                                                                                            )}
                                                                                            {order.colorCodeReason && 
                                                                                                <>
                                                                                                    <i
                                                                                                        className="dripicons-warning font-size-13 align-middle ml-1" style={{color:"red"}}
                                                                                                        title={
                                                                                                            order.colorCodeReason
                                                                                                                ? order.colorCodeReason
                                                                                                                : ""
                                                                                                        }
                                                                                                    ></i>
                                                                                                    <span
                                                                                                        style={{margin: 0, padding: 0, color:"red"}}
                                                                                                        className="font-size-13"
                                                                                                    >
                                                                                                        {" "}{order.colorCodeReason && order.colorCodeReason}
                                                                                                    </span>
                                                                                                </>
                                                                                            }

                                                                                            <span
                                                                                                style={{margin: 0, padding: 0}}
                                                                                                className="font-size-12 font-weight-bold"
                                                                                            >{" | "}</span>

                                                                                            <span
                                                                                                style={{margin: 0, padding: 0}}
                                                                                                className="font-size-12 text-danger"
                                                                                            >
                                                                                                {order.deliveryStatus
                                                                                                    ? order.deliveryStatus ===
                                                                                                        "DELIVER_ASSIGN"
                                                                                                        ? "Assigned"
                                                                                                        : order.deliveryStatus ===
                                                                                                            "DELIVER_ACCEPT"
                                                                                                            ? "Accept"
                                                                                                            : order.deliveryStatus ===
                                                                                                                "DELIVER_ONTOSTORE"
                                                                                                                ? "On route"
                                                                                                                : order.deliveryStatus ===
                                                                                                                    "DELIVER_REACHEDSTORE"
                                                                                                                    ? "Arrived"
                                                                                                                    : order.deliveryStatus ===
                                                                                                                        "DELIVER_LEFTSTORE"
                                                                                                                        ? "Left store"
                                                                                                                        : order.deliveryStatus ===
                                                                                                                            "DELIVER_ONTOCLIENT"
                                                                                                                            ? "Way to drop"
                                                                                                                            : order.deliveryStatus ===
                                                                                                                                "DELIVER_COMPLETED"
                                                                                                                                ? "Clr"
                                                                                                                                : "NA"
                                                                                                    : "NA"}
                                                                                            </span>

                                                                                            <span style={{margin: 0, padding: 0}} className="font-size-12">{" | "}{order.deliveryAddress && order.deliveryAddress.addressLine1}</span>
                                                                                        
                                                                                        
                                                                                            <span className="font-size-12 font-weight-bold">{" | "}{order.customerETATime}</span><span style={{margin: 0, padding: 0, float:"right"}} className="font-size-12 text-dark">{order.storeName && order.storeName.substring(0, 14)}</span>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Fragment>
                                                                        )}
                                                                    </>
                                                                    
                                                                );
                                                            }
                                                        })}
                                                    {this.state.newTransactions.length <= 0 && (
                                                        <Fragment>
                                                            <div scope="row" style={{margin:0, paddingLeft: 10}}>
                                                                <div style={{margin:0, padding:0}}>No records found</div>
                                                            </div>
                                                        </Fragment>
                                                    )}

                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                        
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }


                {this.state.errorMsg ? (
                    <Alert color="danger">
                        {this.state.errorMsg === "No message available"
                        ? "Service failure, Please contact support team."
                        : this.state.errorMsg}
                    </Alert>
                ) : (
                    ""
                )}
                {this.state.order_info ? (
                    <Alert color="success">{this.state.order_info}</Alert>
                ) : (
                    ""
                )}
            

                <Modal
                    isOpen={this.state.newOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleNewOrderModal}
                >
                    <div className="modal-content">
                    <ModalHeader toggle={this.toggleNewOrderModal}>
                        {this.state.messageTitle}
                    </ModalHeader>
                    <ModalBody>{this.state.messageBody}</ModalBody>
                    <ModalFooter>
                        <Button
                        type="button"
                        color="secondary"
                        onClick={this.toggleNewOrderModal}
                        >
                        Close
                        </Button>
                    </ModalFooter>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.subOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleSubOrderModal}
                >
                    <div className="modal-content">
                    <ModalHeader toggle={this.toggleSubOrderModal}>
                        {this.state.transaction?.id}
                    </ModalHeader>
                    <ModalBody>
                        <PopupMenuMobile data={this.state.transaction?.subOrders}
                            allOrders={this.state.transactions}
                            open={
                                this.state.tableAccordion[this.state.transaction?.id]
                            }
                            getTodaysTransactions={
                                this.getTodaysTransactions
                            } />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={this.toggleSubOrderModal}
                        >Close</Button>
                    </ModalFooter>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.acknowledgeOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleAcknowledgeOrderModal}
                >
                    <ModalBody>
                    <b>Acknowledge this order ?</b>
                    <hr />
                    <p className="mb-2">
                        <b>
                        #{this.state.transaction.id && this.state.transaction.id}
                        </b>
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.storeName} /{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.addressLine1}{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.postalCode}
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.deliveryFirstName} /{" "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.addressLine1}{" "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.postalCode}
                    </p>
                    <hr />
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" &&
                        this.state.transaction.storeETATime && (
                        <p className="mb-2">
                            Driver pickup time{" "}
                            <span>{this.state.transaction.storeETATime}</span>
                        </p>
                        )}
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" && (
                        <p className="mb-2">
                        Delivery time{" "}
                        <span>{this.state.transaction.customerETATime}</span>
                        </p>
                    )}
                    </ModalBody>
                    <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.handleAcknowledgeOrderConfirmModalAccept}
                    >
                        Ok
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleAcknowledgeOrderConfirmModalClose}
                    >
                        Cancel
                    </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={this.state.cancelOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleCancelOrderModal}
                >
                    <ModalBody>
                    <b>Are you sure you want to cancel the Order ?</b>
                    <hr />
                    <p className="mb-2">
                        <b>
                        #{this.state.transaction.id && this.state.transaction.id}
                        </b>
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.storeName} /{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.addressLine1}{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.postalCode}
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.deliveryFirstName && this.state.transaction.deliveryFirstName + "/ "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.addressLine1}{" "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.postalCode}
                    </p>
                    <hr />
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" &&
                        this.state.transaction.storeETATime && (
                        <p className="mb-2">
                            Driver pickup time{" "}
                            <span>{this.state.transaction.storeETATime}</span>
                        </p>
                        )}
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" && (
                        <p className="mb-2">
                        Delivery time{" "}
                        <span>{this.state.transaction.customerETATime}</span>
                        </p>
                    )}
                    </ModalBody>
                    <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.handleCancelOrderConfirmModalAccept}
                    >
                        Ok
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleCancelOrderConfirmModalClose}
                    >
                        Cancel
                    </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={this.state.completeOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleCompleteOrderModal}
                >
                    <ModalBody>
                    Are you sure you want to complete the Order ?
                    <hr />
                    <p className="mb-2">
                        <b>
                        #{this.state.transaction.id && this.state.transaction.id}
                        </b>
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.storeName} /{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.addressLine1}{" "}
                        {this.state.transaction.storeAddress &&
                        this.state.transaction.storeAddress.postalCode}
                    </p>
                    <p className="mb-2">
                        {this.state.transaction.deliveryFirstName} /{" "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.addressLine1}{" "}
                        {this.state.transaction.deliveryAddress &&
                        this.state.transaction.deliveryAddress.postalCode}
                    </p>
                    <hr />
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" &&
                        this.state.transaction.storeETATime && (
                        <p className="mb-2">
                            Driver pickup time{" "}
                            <span>{this.state.transaction.storeETATime}</span>
                        </p>
                        )}
                    {this.state.transaction.deliveryOption === "DOOR_DELIVERY" && (
                        <p className="mb-2">
                        Delivery time{" "}
                        <span>{this.state.transaction.customerETATime}</span>
                        </p>
                    )}
                    </ModalBody>
                    <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.handleCompleteOrderConfirmModalAccept}
                    >
                        Ok
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleCompleteOrderConfirmModalClose}
                    >
                        Cancel
                    </Button>
                    </ModalFooter>
                </Modal>
                
                <Modal
                    isOpen={this.state.modal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.togglemodal}
                    size="sm"
                >
                    <div className="modal-content">
                    <ModalHeader toggle={this.togglemodal}>
                        Assign driver
                    </ModalHeader>
                    <ModalBody>
                        <p className="mb-2">
                        <b>
                            #{this.state.transaction.id && this.state.transaction.id}
                        </b>
                        </p>
                        <p className="mb-2">
                        <b>
                            {this.state.transaction.storeName} /{" "}
                            {this.state.transaction.storeAddress &&
                            this.state.transaction.storeAddress.addressLine1}{" "}
                            {this.state.transaction.storeAddress &&
                            this.state.transaction.storeAddress.postalCode}
                        </b>
                        </p>
                        <p className="mb-2">
                        <b>
                            {this.state.transaction.deliveryFirstName} /{" "}
                            {this.state.transaction.deliveryAddress &&
                            this.state.transaction.deliveryAddress
                                .addressLine1}{" "}
                            {this.state.transaction.deliveryAddress &&
                            this.state.transaction.deliveryAddress.postalCode}
                        </b>
                        </p>
                        <div className="table-responsive">
                        <Table className="table table-centered table-nowrap table-sm table-hover table-striped">
                            <thead className="thead-dark">
                            <tr>
                                <th>#Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.availableDrivers.map((driver, key) => {
                                return (
                                <Fragment key={"_order_" + key}>
                                    <tr>
                                    <td style={{ padding: "0px", margin: "0px" }}>
                                        <Link
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Assign the selected driver"
                                        to="#"
                                        onClick={(e) =>
                                            this.selectDriver(
                                            e,
                                            this.state.transaction.id,
                                            driver.id
                                            )
                                        }
                                        className="btn btn-success-outline waves-effect font-weight-bold waves-light mr-2 btn-sm"
                                        >
                                        <span
                                            style={{
                                            textDecoration: "underline",
                                            fontSize: 12,
                                            }}
                                        >
                                            <b>
                                            {driver.firstName} {driver.lastName}{" "}
                                            {"["}
                                            {driver.activeOrders}
                                            {"]"}
                                            </b>
                                        </span>
                                        </Link>
                                    </td>
                                    </tr>
                                </Fragment>
                                );
                            })}
                            {this.state.transactions.length <= 0 && (
                                <Fragment>
                                <tr>
                                    <td>No records found</td>
                                </tr>
                                </Fragment>
                            )}
                            </tbody>
                        </Table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                        type="button"
                        color="danger"
                        onClick={this.togglemodal}
                        >
                        Close
                        </Button>
                    </ModalFooter>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.groupOrderModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="modal-dialog modal-dialog-centered"
                    tabindex="-1"
                    toggle={this.toggleGroupOrderModal}
                    size="sm"
                >
                    <div className="modal-content">
                    <ModalHeader toggle={this.toggleGroupOrderModal}>
                        Group order - Assign driver
                    </ModalHeader>
                    <ModalBody>
                        {this.state.selectedOrders?.length > 0 ? 
                            <p className="mb-2">
                                <b>
                                    #{this.state.selectedOrders && this.state.selectedOrders.join(" | ")}
                                </b>
                            </p>
                            :
                            <p className="mb-2">
                                #No orders found, You can group orders by selecting drop off points in the map which are close to each other and assign the driver.
                            </p>}
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap table-sm table-hover table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>#Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.availableDrivers.map((driver, key) => {
                                    return (
                                        <Fragment key={"_order_" + key}>
                                            <tr>
                                            <td style={{ padding: "0px", margin: "0px" }}>
                                                <Link
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Assign the selected driver"
                                                to="#"
                                                onClick={(e) =>
                                                    this.selectDriverInGroup(
                                                        e,
                                                        driver.id
                                                    )
                                                }
                                                className="btn btn-success-outline waves-effect font-weight-bold waves-light mr-2 btn-sm"
                                                >
                                                <span
                                                    style={{
                                                    textDecoration: "underline",
                                                    fontSize: 12,
                                                    }}
                                                >
                                                    <b>
                                                    {driver.firstName} {driver.lastName}{" "}
                                                    {"["}
                                                    {driver.activeOrders}
                                                    {"]"}
                                                    </b>
                                                </span>
                                                </Link>
                                            </td>
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                                {this.state.transactions.length <= 0 && (
                                    <Fragment>
                                    <tr>
                                        <td>No records found</td>
                                    </tr>
                                    </Fragment>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            color="danger"
                            onClick={this.toggleGroupOrderModal}
                        >
                        Close
                        </Button>
                    </ModalFooter>
                    </div>
                </Modal>
            </React.Fragment>
    );
  }
}

export default EcommerceOrders;
