import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import config from "../../config.json";

//Import mini card widgets
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const optionGroup1 = [
  { label: "Abu Dhabi", value: "Abu Dhabi" },
  { label: "Dubai", value: "Dubai" },
  { label: "Sharjah", value: "Sharjah" },
  { label: "Ajman", value: "Ajman" },
  { label: "Umm Al Quwain", value: "Umm Al Quwain" },
  { label: "Ras Al Khaimah", value: "Ras Al Khaimah" },
  { label: "Fujairah", value: "Fujairah" }
];

class StoreCreate extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      selectedProvince: null,
    };
  }
  

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentDidMount() {
    const { match, location, history } = this.props;

    this.setState({
      selectedProvince: { value: 'Abu Dhabi', label: 'Abu Dhabi' }
    });

    console.log(location);
  }

  handleSubmit = (event, error, values) => {
    this.setState({ error, values });

    console.log("values", event, error, values);

    if (typeof error?.length !== "undefined" && error?.length === 0) {

      if (values) {
        try {
          let driverKey = sessionStorage.getItem("secretKey");
          let driverId = sessionStorage.getItem("userId");

          values.province = this.state.selectedProvince?.value ?? '';

          this.addNewStore(values, driverId, driverKey);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  addNewStore = async (Object, driverId, driverKey) => {
    fetch(config.DELIVERY_API + "addStore/" + driverId + "/" + driverKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Object),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({ errorMsg: data.message || data.error });
        } else {
          this.setState({ errorMsg: "" });
          this.toggle();
          this.form && this.form.reset();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({
          showToast: true,
          toastTitle: "Failed",
          toastMessage: "Please try again later",
        });
      });
  };

  //Select
  handleSelectProvince = (selectedProvince) => {
    this.setState({ selectedProvince });
  };

  render() {
    const { selectedProvince, selectedCountry } = this.state;

    return (
      <React.Fragment>
        <div className="page-content" style={{marginTop:"0px"}}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Stores" gotoLink="store-grid" breadcrumbItem="Add Store" />

            {this.state.errorMsg &&
              <Alert color="danger">{this.state.errorMsg}</Alert>
            }

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm
                      onSubmit={this.handleSubmit}
                      id="editDriverForm"
                      className="needs-validation"
                      ref={(c) => (this.form = c)}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="firstName">Store owner's first name*</Label>
                            <AvField
                              name="firstName"
                              placeholder="First name"
                              type="text"
                              errorMessage="Enter store owner first name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="firstName"
                              value={this.state.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="lastName">Store owner's last name*</Label>
                            <AvField
                              name="lastName"
                              placeholder="Last name"
                              type="text"
                              errorMessage="Enter store owner last name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="lastName"
                              value={this.state.lastName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="companyName">Store name*</Label>
                            <AvField
                              name="companyName"
                              placeholder="Store name"
                              type="text"
                              errorMessage="Enter store name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="companyName"
                              value={this.state.companyName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="email">Email</Label>
                            <AvField
                              name="email"
                              placeholder="Email"
                              type="email"
                              errorMessage="Please provide a valid email id."
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="email"
                              value={this.state.email}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="phoneNumber">Mobile number*</Label>
                            <AvField
                              name="phoneNumber"
                              placeholder="Mobile number"
                              type="phoneNumber"
                              errorMessage="Please provide a valid mobile number."
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="phoneNumber"
                              value={this.state.phoneNumber}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="addressLine1">Address line*</Label>
                            <AvField
                              name="addressLine1"
                              placeholder="Address Line"
                              type="text"
                              errorMessage="Enter Address Line"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="addressLine1"
                              value={this.state.addressLine1}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="addressLine2">Apt / Unit number</Label>
                            <AvField
                              name="addressLine2"
                              placeholder="Apt / Unit number"
                              type="text"
                              errorMessage="Enter Apt / Unit number"
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="addressLine2"
                              value={this.state.addressLine2}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="city">City*</Label>
                            <AvField
                              name="city"
                              placeholder="City"
                              type="text"
                              errorMessage="Enter city"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="city"
                              value={this.state.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">

                          <FormGroup>
                            <Label htmlFor="province">Province*</Label>
                            <Select
                                value={selectedProvince}
                                onChange={this.handleSelectProvince}
                                options={optionGroup1}
                                validate={{ required: { value: true } }}
                                id="province"
                                name="province"
                                errorMessage="Enter province"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                        
                          <FormGroup>
                            <Label htmlFor="postalCode">Postal Code*</Label>
                            <AvField
                              name="postalCode"
                              placeholder="Postal Code"
                              type="text"
                              errorMessage="Enter Postal Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="postalCode"
                              value={this.state.postalCode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>

                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="additionalInfo">Pick up instructions</Label>
                            <AvField
                              name="additionalInfo"
                              placeholder="e.g While pick up can you park in the back side parking lot"
                              type="text"
                              errorMessage="Enter pick up instructions"
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="additionalInfo"
                              value={this.state.additionalInfo}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6"></Col>
                        <Col md="6">
                          <Button
                          color="primary"
                          type="submit"
                          onClick={this.handleSubmit}
                          >
                            Add Store
                          </Button>
                        </Col>
                      </Row>
                      
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                <ModalBody>Added store successfully</ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    tag={Link}
                    to={{
                      pathname: "/store-grid",
                    }}
                    onClick={this.toggle}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default StoreCreate;
