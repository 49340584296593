import axios from "axios";

export const apiUrl = "https://api.schedulestaffpro.com/admintrack/";
export const storeApiUrl =
  "https://api.schedulestaffpro.com/admintrack/v1/api/stores/store/";
export const trackingApiUrl =
  "https://api.schedulestaffpro.com/admintrack/v1/api/track/delivery/";
export const trackingStoreApiUrl =
  "https://api.schedulestaffpro.com/admintrack/v1/api/track/";
export const imageBasePath = "https://ik.imagekit.io/atagfh4iu/";
export const imageSize = "?tr=h-501,w-501";
export const imageSizeCart = "?tr=h-201,w-201";
export const imageSizeForAlert = "?tr=h-201,w-201";
export const imageSizeForHeaderCart = "?tr=h-201,w-201";

class KeyMarvel {
  getStoreKey = () => sessionStorage.getItem("storeKey");
  getClientKey = () => sessionStorage.getItem("clientKey");
  getPrivateKey = () => sessionStorage.getItem("privateKey");
  setClientKey = (key) => sessionStorage.setItem("clientKey", key);
  setStoreKey = (key) => sessionStorage.setItem("storeKey", key);
  setPrivateKey = (key) => sessionStorage.setItem("privateKey", key);

  isAuthenticated = () =>
    sessionStorage.getItem("privateKey") &&
    sessionStorage.getItem("Auth") &&
    sessionStorage.getItem("storeKey");

  logout = () => {
    sessionStorage.removeItem("privateKey");
    sessionStorage.removeItem("storeKey");
    sessionStorage.setItem("Auth", false);
  };

  validateAuth = function (data) {
    return axios.post(trackingApiUrl + "validateAuth", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  shopRegistration = function (data) {
    return axios.post(trackingApiUrl + "registerStore", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getNotifications = function (data) {
    let storeId = this.getStoreKey();
    let storeKey = this.getPrivateKey();
    return axios.get(
      trackingApiUrl +
        "notify/" +
        storeId +
        "/notifications?storeSecretKey=" +
        storeKey +
        "&count=20"
    );
  };

  updateNotifications = function (data) {
    let storeId = this.getStoreKey();
    let storeKey = this.getPrivateKey();
    return axios.post(
      trackingApiUrl +
        "notify/" +
        storeId +
        "/notifications/notification/" +
        data +
        "?storeSecretKey=" +
        storeKey,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  getPastTransactionsByDateType = function (apiName, dateType) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();

    return axios.post(
      trackingApiUrl + apiName + "/" + driverId + "/" + driverKey,
      {
        pageNo: 0,
        pageSize: 1000,
        dateType,
      }
    );
  };

  getTransactionsByDate = function (apiName, startDate, endDate) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();

    return axios.post(
      trackingApiUrl + apiName + "/" + driverId + "/" + driverKey,
      {
        pageNo: 0,
        pageSize: 1000,
        startDate,
        endDate,
      }
    );
  };

  getTransactions = function (apiName) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();

    return axios.post(
      trackingApiUrl + apiName + "/" + driverId + "/" + driverKey,
      {
        pageNo: 0,
        pageSize: 100,
      }
    );
  };

  getActiveDrivers = function () {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}findActiveDrivers/${driverId}/${driverKey}`
    );
  };

  updateToken = function (token, driverId, driverKey) {
    return axios.post(
      trackingApiUrl + "addMobileDeviceData" + "/" + driverId + "/" + driverKey,
      {
        deviceToken: token,
        deviceType: "web",
      }
    );
  };

  acknowledgeOrder = function (orderId) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}updateOrderByStatus/${driverId}/${driverKey}/${orderId}/ACKNOWLEDGED`
    );
  };

  cancelOrder = function (orderId) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}updateOrderByStatus/${driverId}/${driverKey}/${orderId}/CANCELLED`
    );
  };

  completeOrder = function (orderId) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}updateOrderByStatus/${driverId}/${driverKey}/${orderId}/COMPLETED`
    );
  };

  updateDriverInGroupOrder = function (orderIds, assignedDriver) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.post(
      `${trackingApiUrl}assignOrder/${driverId}/${driverKey}/${assignedDriver}`, orderIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  updateDriver = function (orderId, assignedDriver) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}assignOrder/${driverId}/${driverKey}/${orderId}/${assignedDriver}`
    );
  };

  deselectDriver = function (orderId, assignedDriver) {
    let driverId = this.getClientKey();
    let driverKey = this.getPrivateKey();
    return axios.get(
      `${trackingApiUrl}unAssignOrder/${driverId}/${driverKey}/${orderId}`
    );
  };

  getTransaction = function (data) {
    let storeId = this.getStoreKey();
    let storeKey = this.getPrivateKey();
    return axios.get(
      trackingApiUrl +
        "transaction/" +
        storeId +
        "/transaction/" +
        data +
        "?storeSecretKey=" +
        storeKey
    );
  };

  unGroupOrder = function (orderId) {
    let storeId = this.getClientKey();
    let storeKey = this.getPrivateKey();
    return axios.get(
      trackingApiUrl + `ungroupOrder/${storeId}/${storeKey}/${orderId}`
    );
  };

  getResponseTime = function () {
    console.log("getDriverResponseTime ");
    return axios.get(trackingApiUrl + "getDriverResponseTime/" + this.getClientKey() + "/" + this.getPrivateKey());
  };

  updateResponseTime = function (data) {
    console.log("updateDriverResponseTime " + data);
    return axios.get(trackingApiUrl + "updateDriverResponseTime/" + this.getClientKey() + "/" + this.getPrivateKey() + "/" + data);
  };

  searchCart = function (data) {
    let storeId = this.getStoreKey();
    return axios.get(
      apiUrl + "product/getProductCategories/" + storeId + "/" + data
    );
  };

  getProducts = function (selectedCategory, currentPagePosition = 0) {
    let storeId = this.getStoreKey();
    console.log("page number" + currentPagePosition);
    return axios.get(
      storeApiUrl +
        storeId +
        "/products/categories/category/" +
        selectedCategory
    );
  };

  updateProduct = function (product) {
    console.log("updated product is " + JSON.stringify(product));
    let products = [];
    products.push(product);
    let storeKey = this.getPrivateKey();
    return axios.post(
      storeApiUrl +
        this.getClientKey() +
        "/products?storeSecretKey=" +
        storeKey,
      products,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  updateProductImage = function (product) {
    console.log("updated product is " + JSON.stringify(product));
    let products = [];
    products.push(product);
    let storeKey = this.getPrivateKey();
    return axios.post(
      storeApiUrl +
        this.getClientKey() +
        "/products?storeSecretKey=" +
        storeKey,
      products,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  addProduct = function (product) {
    console.log("add product is " + JSON.stringify(product));
    let products = [];

    products.push({
      ...product,
      storeId: this.getStoreKey(),
    });
    let storeKey = this.getPrivateKey();
    return axios.post(
      storeApiUrl +
        this.getClientKey() +
        "/products?storeSecretKey=" +
        storeKey,
      products,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  getAccount = function () {
    console.log(this.getClientKey());
    return axios.get(apiUrl + "clientDetails/" + this.getClientKey());
  };

  forgotPassword = function () {
    console.log(this.getClientKey());
    return axios.get(apiUrl + "forgotPassword/" + this.getClientKey());
  };

  addAccount = function (data) {
    console.log("addClient" + data);
    return axios.post(apiUrl + "addClient", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getStores = function () {
    return axios.get(storeApiUrl + this.getStoreKey() + "/categories");
  };

  downloadSampleCSV = function () {
    return axios.get(
      trackingStoreApiUrl +
        "store/downloadSampleCSV/" +
        this.getClientKey() +
        "/" +
        this.getPrivateKey(),
      { responseType: "blob" }
    );
  };

  uploadBulkDataCSV = function (data) {
    return axios.post(
      trackingStoreApiUrl +
        "store/bulkUpload/" +
        this.getPrivateKey() +
        "/" +
        this.getClientKey(),
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  updateAccount = function (data) {
    console.log("updateClient" + data);
    return axios.post(apiUrl + "updateClient/" + this.getClientKey(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  createObject = function (data) {
    let obj = {
      address: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        defaultDeliveryAddress: true,
        postalCode: data.postalCode,
        province: data.province,
      },
      businessName: data.nameofBusiness,
      contactName: data.contactName,
      email: data.email,
      existingApps: data.alreadyRegistered,
      phoneNumber: data.phoneNumber,
      typeOfBusiness: data.typeofBusiness,
      time: data.bestTimeToContact,
    };
    return obj;
  };
}

export default new KeyMarvel();
