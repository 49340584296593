import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        userRole: "",
        companyName: ""
      };
    }
  
    componentDidMount = () => {
      var userRoleInSession = sessionStorage.getItem("userRole");
      var companyNameInSession = sessionStorage.getItem("companyName");
      this.setState({
        userRole: userRoleInSession,
        companyName: companyNameInSession
      });
    }

    render() {return (
        <React.Fragment>
            {this.state.userRole && this.state.userRole === "SUPER_ADMIN" && 
                <footer className="footer" style={{margin:0, padding:0}}>
                    <Container fluid={true}>
                        <Row>
                            <Col md={12}>
                                <div className="text-sm-right d-none d-sm-block text-white">{new Date().getFullYear()} © DevTrac.</div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            }
            {this.state.userRole && this.state.userRole !== "SUPER_ADMIN" && 
                <footer className="footer" style={{margin:0, padding:10}}>
                    <Container fluid={true}>
                        <Row>
                            <Col md={9}>
                                {this.state.companyName && this.state.companyName != "null" && <div className="text-sm-left d-none d-sm-block text-white">
                                    Store #{this.state.companyName.charAt(0).toUpperCase() + this.state.companyName.substr(1).toLowerCase()}
                                </div>}
                            </Col>
                            <Col md={3}>
                                <div className="text-sm-right d-none d-sm-block text-white">{new Date().getFullYear()} © DevTrac.</div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            }
        </React.Fragment>
    );
}}

export default Footer;
