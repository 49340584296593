import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import StoreMenu from "../CommonForBoth/TopbarDropdown/StoreMenu";
import DriverMenu from "../CommonForBoth/TopbarDropdown/DriverMenu";
import logoLight from "../../assets/images/logo.png";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from 'react-i18next';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isSearch: false, 
      userRole: ""
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  componentDidMount = () => {
    var userRoleInSession = sessionStorage.getItem("userRole");
    this.setState({
      userRole: userRoleInSession
    });
  }


  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header" style={{height:"40px"}}>
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/">
                  <span>
                    <img src={logoLight} alt="" height="38px" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex" style={{height:"40px"}}>
              <div className="d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <div className="d-none d-inline-block ml-1">
                <a
                    style={{color:"#fff", size:21, height:40}} className="btn waves-effect"
                    href="/ecommerce-orders"
                    role="button"
                >
                    <span style={{ textDecoration: "underline" }}>Dispatch</span>
                </a>
              </div>

              <div className="d-none d-inline-block ml-1">
                <a
                    style={{color:"#fff", size:21, height:40}} className="btn waves-effect"
                    href="/dashboard"
                    role="button"
                >
                    <span style={{ textDecoration: "underline" }}>Report</span>
                </a>
              </div>
              
              {this.state.userRole === "SUPER_ADMIN" && <StoreMenu />}

              {this.state.userRole === "SUPER_ADMIN" && <DriverMenu />}

              <NotificationDropdown />

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
