import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { JsonToTable } from "react-json-to-table";
import { Container, Row, Col, Label, FormGroup, Modal,
    ModalHeader, Card, CardBody, Table,
    ModalBody,
    ModalFooter, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns'

// Pages Components
import WelcomeComp from "./WelcomeComp";
import KeyMarvel from "../../helpers/keymarvel";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';
import MapReport from "../../components/Common/MapReport";

import config from "../../config.json"
import API from "../../config.json";
import Axios from "axios";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [
                { title: "Orders", iconClass: "bx-copy-alt", description: "0" },
                { title: "Revenue", iconClass: "bx-archive-in", description: "$ 0" },
                { title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "$ 0" }
            ],
            email: [
                { title: "Last 7 days", linkto: "#", isActive: true },
                { title: "Last 30 days", linkto: "#", isActive: false },
                { title: "Last 90 days", linkto: "#", isActive: false }
            ],
            modal: false,
            transactions: [],
            mapDatas: [],
            uniqueStores: [],
            removedStores: [],
            selectedTab: "Last7",
            startDate: new Date(),
            endDate: new Date(),
            orderInfo: {},
            userRole: "",
            companyName: "",
            acknowledgeOrderModal: false,
            reportType:"table"
        };
        this.startDateChange.bind(this);
        this.endDateChange.bind(this);
    }

    state = {
        client:{},
    }

    showAcknowledgeOrderModal() {
        this.toggleAcknowledgeOrderModal();
    }

    toggleAcknowledgeOrderModal = () => {
        this.setState((prevState) => ({
            acknowledgeOrderModal: !prevState.acknowledgeOrderModal,
        }));
    };

    handleAcknowledgeOrderConfirmModalClose = () => {
        this.setState({
            acknowledgeOrderModal: false,
        });
    };

    handleAcknowledgeOrderConfirmModalAccept = () => {
        this.setState({
            acknowledgeOrderModal: false,
        });
    };

    startDateChange = date => {
        this.setState({
            startDate: date
        });
    };

    endDateChange = date => {
        this.setState({
            endDate: date
        });
    };

    getCenter = async () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");

        const result = await Axios.get(
          API.DELIVERY_API + "getMapCenterInfo/" + driverId + "/" + driverKey,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).catch((error) => console.log(error.message));
        if (result) {
          //console.log(result.data?.location);
          
          this.setState({mapCenter: {
            lat: result.data?.location[0],
            lng: result.data?.location[1],
          }});
        }
      };

    componentDidMount = async () => {
        var userRoleInSession = sessionStorage.getItem("userRole");
        var companyNameInSession = sessionStorage.getItem("companyName");
        this.setState({
            userRole: userRoleInSession,
            companyName: companyNameInSession,
        });
        await this.getAllTransactions();
        await this.getCenter();
    };

    getOrderInfo = async (orderId) => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
                  
        fetch(
          config.DELIVERY_API + "getOrderInfo/" + orderId + "/" + driverId + "/" + driverKey,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              this.setState({ errorMsg: data.message || data.error });
            } else {
              console.log(data);
              if (data) {
                
                try {
                  this.setState({
                    orderInfo: data
                  });

                  this.showAcknowledgeOrderModal();
                } catch (error) {
                    console.error("Error:", error);
                }
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            this.setState({ errorMsg: "Fail to load" });
          });
      };

    getAllTransactionsForMap = async () => {
        let apiName = "getOldOrders";
        await KeyMarvel.getTransactionsByDate(apiName, this.state.startDate, this.state.endDate).then((result) => {
            if (result && result.status === 200) {
                console.log(result.data.content.length);
                var rows = [];
                
                result.data.content.map(order => {

                    return rows.push({
                        orderId: order.id,
                        driverName: order.driverName,
                        store: order.storeName,
                        status: order.status,
                        dropOffName: order.deliveryFistName != null ? order.deliveryFistName : "" + " " + order.deliveryLastName,
                        dropOff: order.deliveryAddress?.addressLine2 != null && order.deliveryAddress?.addressLine2 != '' ? order.deliveryAddress?.addressLine1 + ", " +  order.deliveryAddress?.addressLine2 + ", " + order.deliveryAddress?.city 
                        : order.deliveryAddress?.addressLine1 + ", " + order.deliveryAddress?.city,
                        dropOffPhone: order.deliveryMobileNumber,
                        completedDate: order.formatDate.substring(5),
                        dropOffLocation: order.location
                    });
                });

                console.log(rows);

                const uniqueStores = [...new Set(rows.map(item => item.store))];

                this.setState({
                    mapDatas: rows,
                    uniqueStores: uniqueStores,
                    orderInfo: {},
                    reportType: 'map'
                });

                
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
        });
    };

    getAllTransactions = async () => {
        let apiName = "getOldOrders";
        await KeyMarvel.getTransactionsByDate(apiName, this.state.startDate, this.state.endDate).then((result) => {
            if (result && result.status === 200) {
                console.log(result.data.content.length);
                var rows = result.data.content.map(order => ({
                    orderId: (this.state.userRole === "SUPER_ADMIN" ? <Link style={{margin:0, padding:0, textDecoration: "underline"}}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Show order info"
                        to="#"
                        onClick={() =>
                            this.getOrderInfo(order.id)
                        }
                        className="text-body font-size-13"
                    >
                        <span style={{margin:0, padding:0, textDecoration: "underline"}}>{order.id}</span>
                    </Link> : <span>{order.id}</span>),
                    driverName: order.driverName,
                    store: order.storeName,
                    status: order.status,
                    dropOffName: order.deliveryFistName != null ? order.deliveryFistName : "" + " " + order.deliveryLastName,
                    dropOff: order.deliveryAddress?.addressLine2 != null && order.deliveryAddress?.addressLine2 != '' ? order.deliveryAddress?.addressLine1 + ", " +  order.deliveryAddress?.addressLine2 + ", " + order.deliveryAddress?.city 
                    : order.deliveryAddress?.addressLine1 + ", " + order.deliveryAddress?.city,
                    dropOffPhone: order.deliveryMobileNumber,
                    completedDate: order.formatDate.substring(5)
                }));
                var columns = [
                    {
                      label: "Order id",
                      field: "orderId",
                      sort: "asc",
                      width: "50px"
                    },
                    {
                        label: "Date",
                        field: "completedDate",
                        sort: "desc"
                      },
                    {
                      label: "Store",
                      field: "store",
                      sort: "asc"
                    },
                    {
                        label: "Driver name",
                        field: "driverName",
                        sort: "asc"
                    },
                    {
                        label: "Drop off",
                        field: "dropOff",
                        sort: "asc"
                    },
                    {
                        label: "Name #",
                        field: "dropOffName",
                        sort: "asc"
                    },
                    {
                        label: "Phone #",
                        field: "dropOffPhone",
                        sort: "asc"
                    },
                    {
                      label: "Status",
                      field: "status",
                      sort: "asc"
                    }
                ];

                var transactionDatas = {rows, columns}

                console.log(transactionDatas);

                this.setState({
                    transactions: transactionDatas,
                    orderInfo: {},
                    reportType: 'table'
                });
            } else {
                console.error("error" + JSON.stringify("Failed to connect"));
            }
        });
    };

    handleChange = (e) => {
        var array = [...this.state.removedStores];
        console.log('data selected ', array); // make a separate copy of the array
        var index = array.indexOf(e.target.value);
        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(e.target.value);
        }
        console.log('data ', array);
        this.setState({removedStores: array});
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content" style={{margin:0}}>
                    <Container fluid>
                        <Row>
                            <Col xl="8">
                                <div className="mb-4">
                                    <FormGroup className="mb-4" row>
                                        <Label className="col-form-label col-lg-2">Order date</Label>
                                        <div className="input-daterange input-group" data-provide="datepicker">
                                            <Col sm="2">
                                                <DatePicker className="form-control"
                                                    selected={this.state.startDate}
                                                    onChange={this.startDateChange}
                                                />
                                            </Col>
                                            <Col sm="2">
                                                <DatePicker className="form-control"
                                                    selected={this.state.endDate}
                                                    onChange={this.endDateChange}
                                                />
                                            </Col>
                                        
                                            <Col sm="4">
                                                <a
                                                    className="btn btn-success mr-4"
                                                    onClick={() => this.getAllTransactions()}
                                                    role="button"
                                                >
                                                    Show orders
                                                </a>
                                            
                                                <a
                                                    className="btn btn-danger mr-4"
                                                    onClick={() => this.getAllTransactionsForMap()}
                                                    role="button"
                                                >
                                                    Show orders in map
                                                </a>
                                            </Col>
                                        </div>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col xl="4">

                            </Col>
                        </Row>
                        {this.state.reportType == 'table' && 
                            <Row>
                                <Col xl="12">
                                    <span>Total orders for the provided dates : {this.state.transactions.rows ? this.state.transactions.rows.length : 0}</span>
                                    <MDBDataTable entries={100} small noBottomColumns striped bordered hover data={this.state.transactions} />
                                </Col>
                            </Row>
                        }

                        {this.state.reportType == 'map' && 
                            <Row>
                                <Col xl="10">
                                    <MapReport mapCenter={this.state.mapCenter} dropOffMapCoordinates={this.state.mapDatas} removedStores={this.state.removedStores} />
                                </Col>
                                <Col xl="2">
                                    <Row>
                                        <Col lg="12">
                                            <span style={{fontSize: 11, fontWeight: 'bold'}}>Filter by Stores</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <div style={{overflowY: "auto", height: "570px"}} >
                                                {this.state.uniqueStores.map((store, index) => {
                                                    return (
                                                        <div id={index} className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" id={"inlineCheckbox"+store} value={store} checked={!this.state.removedStores?.includes(store)} onChange={this.handleChange}></input>
                                                            <label className="form-check-label" style={{fontSize: 11}} htmlFor={"inlineCheckbox"+store}>{store}</label>
                                                        </div>)})}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }

                        <Modal
                            isOpen={this.state.acknowledgeOrderModal}
                            role="dialog"
                            autoFocus={true}
                            centered={true}
                            className="modal-dialog modal-dialog-centered modal-xl"
                            tabIndex="-1"
                            toggle={this.toggleAcknowledgeOrderModal}
                            >
                                <ModalBody>
                                    <Row>
                                        <Col lg="12">
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Label className="text-primary" htmlFor="storeName">Order Info</Label>
                                                                <span className="font-weight-bold text-danger"> {this.state.orderInfo.id}</span>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                
                                                    <Row>
                                                        <Col md="8">
                                                            <Row>
                                                                <Col md="12">
                                                                    <FormGroup>
                                                                        <Label
                                                                        className="font-weight-bold text-primary"
                                                                        style={{ margin: 0, padding: 0 }}
                                                                        >
                                                                            Delivery Info
                                                                        </Label>
                                                                        <hr style={{ margin: 0, padding: 0 }} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="4">
                                                                    <FormGroup className="select2-container">
                                                                        <Label>Address type</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.selectedAddressType}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                            
                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>First name</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryFistName}</span>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>Last name</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryLastName}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="6">
                                                                    <FormGroup>
                                                                        <Label>Address line 1</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryAddress?.addressLine1}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Apt / Unit number</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryAddress?.addressLine2}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="4">
                                                                <FormGroup>
                                                                    <Label>Postal code</Label>
                                                                    <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryAddress?.postalCode}</span>
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>City</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryAddress?.city}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>Province</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryAddress?.province}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label htmlFor="toAdditionalInfo">
                                                                        Mobile number
                                                                        </Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.deliveryMobileNumber}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            
                                                                <Col md="4">
                                                                    <FormGroup className="select2-container">
                                                                        <Label>Payment info*</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.collectionType}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="4">
                                                                    <FormGroup className="select2-container">
                                                                        <Label>Total amount $</Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.collectionAmount}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label htmlFor="toAdditionalInfo">
                                                                            Additional info
                                                                        </Label>
                                                                        <span className="font-weight-bold text-danger"> {this.state.orderInfo.notes}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md="4">
                                                            {this.state.userRole === "ADMIN" ||
                                                            this.state.userRole === "SUPER_ADMIN" && 
                                                            <>
                                                                <Row>
                                                                    <Col md="12" className="mt-2">
                                                                        <FormGroup>
                                                                        <Label
                                                                            className="font-weight-bold text-primary"
                                                                            style={{ margin: 0, padding: 0 }}
                                                                        >
                                                                            Store Info
                                                                        </Label>
                                                                        <hr style={{ margin: 0, padding: 0 }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <span className="font-weight-bold">Store name </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeName}</span>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <span className="font-weight-bold">Store phone number </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeMobileNumber}</span>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <span className="font-weight-bold">Address </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeAddress?.addressLine1}</span>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <span className="font-weight-bold text-danger">{this.state.orderInfo.storeAddress?.addressLine2}</span>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <span className="font-weight-bold">Spl instructions </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeNotes !== null && this.state.orderInfo.storeNotes !== '' ? this.state.orderInfo.storeNotes : 'NA'}</span>
                                                                    </Col>
                                                                    <hr/>
                                                                </Row>
                                                                
                                                            </>
                                                            }
                                                            <Row>
                                                                <Col md="12" className="mt-2">
                                                                    <FormGroup>
                                                                        <Label
                                                                        className="font-weight-bold text-primary"
                                                                        style={{ margin: 0, padding: 0 }}
                                                                        >
                                                                            Driver Info
                                                                        </Label>
                                                                        <hr style={{ margin: 0, padding: 0 }} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {this.state.orderInfo.deliveryStatus !== 'DELIVER_NOTASSIGN' && this.state.orderInfo.driverName && <Col md="12">
                                                                    <span className="font-weight-bold text-danger">{this.state.orderInfo.driverName}</span>, <span className="font-weight-bold text-danger">{this.state.orderInfo.driverPhoneNumber}</span>
                                                                </Col>}
                                                                {this.state.orderInfo.deliveryStatus !== 'DELIVER_NOTASSIGN' && this.state.orderInfo.driverName && <Col md="12">
                                                                    <div className="table-responsive">
                                                                        <Table className="table table-sm table-bordered table-nowrap table-hover">
                                                                            <thead className="thead-dark" style={{position: "sticky", top: 0, zindex: 1}}>
                                                                                <tr>
                                                                                    <th>Status</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.orderInfo.orderSteps?.length > 0 &&
                                                                                    this.state.orderInfo.orderSteps.map((orderStep, key) => {
                                                                                        return (
                                                                                            <Fragment key={"_order_step_" + key}>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span className="font-weight-bold text-danger">{orderStep.deliveryStatus
                                                                                                        ? orderStep.deliveryStatus ===
                                                                                                            "DELIVER_ASSIGN"
                                                                                                            ? "Assigned"
                                                                                                            : orderStep.deliveryStatus ===
                                                                                                                "DELIVER_ACCEPT"
                                                                                                                ? "Accept"
                                                                                                                : orderStep.deliveryStatus ===
                                                                                                                    "DELIVER_ONTOSTORE"
                                                                                                                    ? "On route"
                                                                                                                    : orderStep.deliveryStatus ===
                                                                                                                        "DELIVER_REACHEDSTORE"
                                                                                                                        ? "Arrived"
                                                                                                                        : orderStep.deliveryStatus ===
                                                                                                                            "DELIVER_LEFTSTORE"
                                                                                                                            ? "Left store"
                                                                                                                            : orderStep.deliveryStatus ===
                                                                                                                                "DELIVER_ONTOCLIENT"
                                                                                                                                ? "Way to drop"
                                                                                                                                : orderStep.deliveryStatus ===
                                                                                                                                    "DELIVER_COMPLETED"
                                                                                                                                    ? "Clear"
                                                                                                                                    : "NA"
                                                                                                        : "NA"}</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>{orderStep?.completeDate && format(parseISO(orderStep?.completeDate), 'dd/MM/yyyy hh:mm a')} </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </Fragment>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>}
                                                                <Col md="12">
                                                                    <span className="font-weight-bold">Order status </span><span className="font-weight-bold text-danger">{this.state.orderInfo.status}</span>
                                                                </Col>
                                                                <Col md="12">
                                                                    <span className="font-weight-bold">Order created </span><span className="font-weight-bold text-danger">{this.state.orderInfo.createDate && format(parseISO(this.state.orderInfo.createDate), 'dd/MM/yyyy hh:mm a')}</span>
                                                                </Col>
                                                                <Col md="12">
                                                                    <span className="font-weight-bold">Order last updated </span><span className="font-weight-bold text-danger">{this.state.orderInfo.updateDate && format(parseISO(this.state.orderInfo.updateDate), 'dd/MM/yyyy hh:mm a')}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                
                                                    {this.state.orderInfo.items?.length > 0 &&
                                                        <Row>
                                                            <Col md="12">
                                                                <div className="table-responsive">
                                                                    <Table className="table table-sm table-bordered table-nowrap table-hover">
                                                                        <thead className="thead-dark" style={{position: "sticky", top: 0, zindex: 1}}>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Qty</th>
                                                                                <th>Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.orderInfo.items?.length > 0 &&
                                                                                this.state.orderInfo.items.map((item, key) => {
                                                                                    return (
                                                                                        <Fragment key={"_order_" + key}>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span>{item?.name} </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>{item?.quantity} </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>{item.price} </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </Fragment>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.handleAcknowledgeOrderConfirmModalAccept}
                                    >
                                    Ok
                                    </Button>
                                    <Button
                                    color="secondary"
                                    onClick={this.handleAcknowledgeOrderConfirmModalClose}
                                    >
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
